@import '../../shared/styles/colors.scss';
.chain-container {
  .standard-settings {
    display: flex;
    margin-bottom: 5px;
    .project-form {
      display: -webkit-box;
      .ant-row.ant-form-item {
        margin: 0 20px 3px 0;
        width: 250px;
      }
      &.ant-form.version-inactive {
        .ant-form-item-control.has-error {
          .ant-input.ant-input-disabled.disabled-input {
            border: none !important;
          }
          .ant-form-explain {
            display: none;
          }
        }
        .ant-input.ant-input-disabled {
          background-color: $white !important;
          border: none !important;
          color: $navy !important;
          &::placeholder {
            color: $lightSilver;
          }
        }
        .ant-select-disabled {
          .ant-select-selection {
            background-color: $white !important;
            border: none !important;
            color: $navy !important;
          }
          .ant-select-arrow {
            display: none !important;
          }
        }
        .ant-radio-wrapper-disabled {
          span {
            @include font(sm);
            color: $silver;
            .ant-radio-inner {
              &::after {
                background-color: $ash !important;
              }
            }
          }
        }
        .ant-radio-wrapper-checked {
          span {
            color: $navy !important;
          }
        }
      }
    }
  }
  .chain-buttons-container {
    border: 1px solid $ash;
    padding: 3px;
    display: flex;
    width: fit-content;
    .button-text {
      @include font(e14);
      font-weight: bold;
    }
    .icon-component {
      margin-right: 5px;
    }
    .show-parts-button {
      display: flex;
      align-items: center;
      padding: 0 10px;
      border-right: 1px solid $ash;
      &:hover {
        cursor: pointer;
      }
    }
    .preview-chain-button {
      display: flex;
      padding: 0 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .chain-cost {
    margin: 0 40px 11px 0;
    .label {
      @include font(sm-med);
      color: $novaBlue;
      line-height: 28px;
    }
    .value {
      @include font(sm-med);
    }
  }
}
