@import '../../../../shared/styles/colors.scss';
.projects-rows-container {
  padding: 20px 23px;
  background-color: $white;
  border-radius: 4px;
  width: 1100px;
  margin: 0 0 8px 0;
  &:hover {
    cursor: pointer;
    background-color: $lightSilver;
  }
  .first-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
    position: relative;
    .project-name {
      @include font(sm-bold);
      margin: 0 20px 0 0;
      max-width: 350px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .project-number {
      display: block;
      @include font(sm);
      margin-right: 16px;
      font-weight: 500;
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .project-version {
      margin-right: 16px;
      background-color: $novaBlue;
      height: 28px;
      width: 28px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      .version-index {
        @include font(sm-med);
        color: $white;
        margin: 0;
      }
    }
    h4 {
      @include font(sm-med);
      margin: 0;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.last-updated {
        font-weight: normal;
        margin-left: auto;
        margin-right: 16px;
      }
      &.cost-estimation {
        width: 120px;
        text-align: right;
        display: block;
        margin-right: 16px;
      }
    }
  }
  .second-row {
    @include font(b);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    h4 {
      font-weight: normal;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.project-status {
        display: flex;
        @include font(b);
        min-width: fit-content;
        color: $novaBlue;
        background-color: #e5f0f7;
        border-radius: 2px;
        font-size: 14px;
        padding: 0 8px;
        margin-right: 16px;
        .icon-component {
          margin-right: 4px;
        }
      }
      &.project-info {
        margin-right: 16px;
      }
    }
  }
}
