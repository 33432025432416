@import '../../../../shared/styles/colors.scss';
#change-password {
  width: 470px;
  height: 470px;
  position: relative;
  .change-password {
    &__title {
      @include font(h3);
      color: $novaBlue;
      border-bottom: 1.5px solid $cloud;
      padding: 20px;
      margin: 0;
    }
    &__button {
      position: absolute;
      right: 20px;
      top: 420px;
      .cancel {
        margin-right: 10px;
      }
    }
  }
  .ant-form {
    padding: 20px;
  }
  .icon-component {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
