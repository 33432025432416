@import '../../shared/styles/colors.scss';

#user-management {
  width: 1200px;
  .user-management {
    &__title {
      margin: 40px 0;
      display: flex;
      align-items: flex-end;
      h1 {
        @include font(h1);
        color: $novaBlue;
        margin-bottom: 0;
      }
      .tm-button {
        margin-left: 20px;
      }
    }
  }
}
