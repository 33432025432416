@import '../../../../shared/styles/colors.scss';

.tube-width {
  width: 454px;
  height: 100%;
  &__top {
    padding: 16px 24px;
    border-bottom: 1px solid $cloud;
    &-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 8px;
      h1 {
        @include font(h3);
        margin: 0;
        color: $novaBlue;
      }
    }
    p {
      color: $ash;
      font-family: Eurostile;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 0;
    }
  }
  &__middle-container {
    padding: 32px 50px;
    margin-bottom: 0;
    .no-rows {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
      @include font(h4);
      color: $silver;
    }
  }
  &__bottom-container {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px 32px;
  }
}

.u-mr-16 {
  margin-right: 16px;
}
