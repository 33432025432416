@import '../../styles/colors.scss';

.spt-alert {
  width: auto;
  padding: 12px 12px 12px 48px !important;
  &.ant-alert-with-description {
    > .ant-alert-icon {
      top: 12px;
      left: 16px;
      font-size: 22px;
    }
    > .ant-alert-close-icon {
      top: 12px;
      right: 12px;
    }
  }
  > span.ant-alert-message:empty {
    display: none;
  }
  &.ant-alert-success {
    background-color: rgba($positive, 0.1);
    border: 1px solid $positive;
    > .ant-alert-icon {
      color: $positive;
    }
  }
  &.ant-alert-info {
    background-color: rgba($bourbon, 0.1);
    border: 1px solid $bourbon;
    > .ant-alert-icon {
      color: $bourbon;
    }
  }
  .ant-alert-close-icon {
    &:focus-visible {
      border-radius: 2px;
      box-shadow: 0 0 0 2px $novaBlue;
      svg {
        color: $deepNavy;
      }
    }
    &:hover svg {
      color: $deepNavy;
    }
  }
  &__container {
    width: 100%;
  }
}
