$ash: #9196a1;
$bourbon: #dda200;
$cobalt: #28479b;
$cloud: #f0eeee;
$darkAsh: #6b707d;
$deepNavy: #0e183c;
$gold: #febf10;
$lightCloud: #f9f8f8;
$lightSilver: #eeeef0;
$mediumCloud: #faf9f9;
$mediumSilver: #d9d9d9;
$navy: #173261;
$novaBlue: #006bb6;
$negative: #dd1c1a;
$positive: #39aa03;
$silver: #c0c5cf;
$white: #fff;

@mixin font($f) {
  @if $f == h1 {
    color: $navy;
    font-family: 'Eurostile';
    font-size: 45px;
    font-weight: 900;
    letter-spacing: 0.28px;
    line-height: 45px;
  }
  @if $f == h2 {
    color: $navy;
    font-family: 'Eurostile';
    font-size: 28px;
    font-weight: 900;
    letter-spacing: 0.25px;
    line-height: 45px;
  }
  @if $f == h3 {
    color: $navy;
    font-family: 'Eurostile';
    font-size: 35px;
    font-weight: 900;
    letter-spacing: 0.25px;
    line-height: 42px;
  }
  @if $f == h4 {
    color: $navy;
    font-family: 'Museo-Sans';
    font-size: 20px;
    letter-spacing: -0.58px;
    line-height: 39px;
  }
  @if $f == h5 {
    color: $navy;
    font-family: 'Museo-Sans';
    font-size: 16px;
    line-height: 28px;
  }
  @if $f == h6 {
    color: $navy;
    font-family: 'Eurostile';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  @if $f == sm {
    color: $navy;
    font-family: 'Eurostile';
    font-size: 16px;
    line-height: 28px;
  }
  @if $f == sm-med {
    color: $navy;
    font-family: 'Eurostile';
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
  }
  @if $f == sm-bold {
    color: $navy;
    font-family: 'Eurostile';
    font-size: 16px;
    font-weight: 900;
    line-height: 28px;
  }
  @if $f == b {
    color: $navy;
    font-family: 'Museo-Sans';
    font-size: 18px;
    line-height: 30px;
  }
  @if $f == b-bold {
    color: $navy;
    font-family: 'Museo-Sans';
    font-size: 18px;
    line-height: 30px;
    font-weight: 900;
  }
  @if $f == e14 {
    color: $navy;
    font-family: 'Eurostile';
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
  }
  @if $f == tiny {
    color: $navy;
    font-family: 'Eurostile';
    font-size: 13px;
    line-height: 12px;
  }
}
