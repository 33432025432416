@import '../../../../shared/styles/colors.scss';

.version-modal-card {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 17px;
  &:hover {
    border: 3px solid $novaBlue;
    border-radius: 4px;
    .header {
      cursor: pointer;
    }
  }
  .header {
    display: flex;
    padding: 12px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    .checkboxcompare {
      margin-right:10px;
      color: $navy;
      font-family: Eurostile;
      font-size: 16px;
      font-weight: 700;
      .ant-form{
        label {
          font-size: 16px;
        }
      }
    }
    .fields {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .field {
        color: $navy;
        font-family: Eurostile;
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
        margin-right: 16px;
        &.masterversion-icon {
          svg {
            vertical-align: sub !important;
          }
        }
        .icon-component {
          display: inline-block;
        }
      }
      .version-chip {
        @include font(sm-med);
        color: $white;
        background: $novaBlue;
        padding: 0px 6px;
        border-radius: 2px;
        margin-right: 16px;
      }
    }
    .actions-menu {
      line-height: 0;
      .ant-dropdown-trigger {
        color: $novaBlue;
        font-weight: 700;
        font-size: 16px;
        align-items: center;
        svg {
          vertical-align: sub !important;
        }
      }
    }
  }
  .content {
    position: relative;
    background-color: $lightCloud;
    padding: 13px 13px 30px 13px;
    .expired-quote {
      position: relative;
      background-color: $white;
      border-radius: 4px;
      box-shadow: 0 1px 3.96px 0.04px rgba(71, 40, 155, 0.1);
      margin-bottom: 12px;
      &__text {
        padding: 10px 48px;
        @include font(sm);
      }
      .icon-component {
        position: absolute;
        top: 12px;
        left: 15px;
      }
    }
    .fields {
      flex-grow: 1;
      display: flex;
      margin-bottom: 10px;
      flex-wrap: wrap;
      align-items: center;
      .field {
        margin-right: 16px;
        label {
          color: $navy;
          font-family: Eurostile;
          font-size: 16px;
          font-weight: 700;
          line-height: 28px;
          margin-right: 8px;
        }
        span {
          @include font(b);
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    .notes {
      margin-bottom: 10px;
      .label {
        @include font(sm-med);
      }
      .body {
        @include font(b);
        font-size: 14px;
        line-height: 24px;
        width: 100%;
        max-height: 200px;
        background-color: $lightCloud;
        border: 1px solid $cloud;
        &:focus {
          outline: none;
          background-color: $white;
        }
      }
    }
    .buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 13px;
      bottom: 8px;
      .save {
        @include font(sm);
        display: inline-block;
        color: $white;
        background: $novaBlue;
        padding: 0px 6px;
        border-radius: 2px;
        margin-right: 16px;
        &.disabled {
        }
      }
    }
  }
}
