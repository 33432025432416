@import 'shared/styles/colors.scss';

#active-projects {
  width: 100%;
  padding: 40px;
  .active-projects {
    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    &__container {
      width: 100%;
    }
    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 40px 60px;
      &__title {
        @include font(h1);
        font-size: 40px;
        color: $novaBlue;
        margin-right: 20px;
      }
      &__dropdowns-container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .custom-date-picker {
          margin-right: 16px;
        }
      }
    }
    &__by-role {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 40px;
    }
  }
}
