@import '../../shared/styles/colors.scss';

#price-configuration {
  width: 100%;
  background-color: $lightCloud;
  min-height: calc(100vh - 75px);

  > section {
    width: 94%;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 1240px) {
      max-width: 1100px;
    }
  }
}

.price-configuration {
  &__title {
    margin: 40px auto 8px;
    display: flex;
    align-items: flex-end;

    h1 {
      @include font(h1);
      color: $novaBlue;
      margin-bottom: 0;
    }

    .tm-button {
      margin-left: 20px;
    }
  }

  &__subtitle {
    margin-bottom: 40px;

    > h5 {
      @include font(e14);
      line-height: 24px;
      font-weight: 600;
    }

    &-date {
      font-weight: 400;
    }
  }
}

.pcf {
  padding: 32px;
  margin-bottom: 48px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 8px 0.1px rgba(23, 50, 97, 0.1);
  &__section {
    width: 100%;
    &-title {
      @include font(h4);
      font-size: 25px;
      font-weight: bold;
    }

    .ant-skeleton-content .ant-skeleton-paragraph > li {
      margin-top: 8px;
      height: 24px;
      & + li {
        margin-top: 24px;
      }
    }
  }
  &__actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  &__action-btn {
    &:first-child {
      margin-right: 20px;
    }
  }
  &-input {
    margin-bottom: 4px;
  }
  &-container {
    .ant-form-extra {
      @include font(tiny);
      line-height: 1;
      font-weight: bold;
      color: $darkAsh;
    }
  }
  &__alert {
    width: 100%;
    margin-bottom: 12px;
    max-width: 500px;
    margin-left: auto;
  }
}
