@import '../../shared/styles/colors.scss';

.cost-import {
  .spt-alert__container {
    max-width: calc(100% - 48px);
    margin: -8px auto 16px;
    @media (min-width: 1248px) {
      max-width: 1200px;
      margin-left: 24px;
    }
    @media (min-width: 1440px) {
      margin-left: 80px;
    }
  }
}
