@import 'src/shared/styles/colors.scss';

.navbar {
  width: 100%;
  box-shadow: 0 1px 3.96px 0.04px rgba(71, 40, 155, 0.1);
  padding: 10px 0;
  display: flex;
  justify-content: center;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo {
    .logo {
      width: 250px;
    }
    .logo-icon {
      width: 40px;
    }
  }
  &__left-container {
    display: flex;
    align-items: center;
  }
}
