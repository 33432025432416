@import '../../../../shared/styles/colors.scss';
.user-detail {
  &__form {
    display: flex;
    flex-wrap: wrap;
    .ant-form-item {
      margin-right: 25px;
      margin-bottom: 10px;
      #user-detail_roles {
        border: none;
      }
      input {
        min-height: 35px;
        &#user-detail_roles {
          border: none !important;
        }
      }
    }
  }
  &__buttons {
    margin-top: 15px;
  }
}
