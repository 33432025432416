@import '../../../../shared/styles/colors.scss';
.material-accessory-item {
  .tm-radio {
    .ant-radio-group {
      margin: 0 !important;
    }
  }
  .ant-tabs {
    &.ant-tabs-card {
      .ant-tabs-card-bar {
        margin-bottom: 0;
        .ant-tabs-nav-container {
          margin-top: 4px;
          height: 28px !important;
        }

        .ant-tabs-tab {
          @include font(b-bold);
          color: $silver;
          border-radius: 4px 4px 0 0;
          font-size: 14px;
          line-height: 1.33;
          padding: 4px 25px;
          background-color: $white;
          &.ant-tabs-tab-active {
            color: $navy;
            background-color: $mediumCloud;
          }
        }
      }
    }
    .ant-tabs-content {
      background-color: $mediumCloud;
    }
  }
}
.version-inactive {
  .ant-form-item-control.has-error {
    .ant-input.ant-input-disabled.disabled-input {
      border: none !important;
    }
    .ant-form-explain {
      display: none;
    }
  }
  .ant-radio-wrapper-disabled {
    span {
      @include font(sm);
      color: $silver !important;
      .ant-radio-inner {
        &::after {
          background-color: $ash !important;
        }
      }
    }
  }
  .ant-radio-wrapper-checked {
    span {
      color: $navy !important;
    }
  }
  .ant-input.ant-input-disabled {
    background-color: $white !important;
    border: none !important;
    color: $navy !important;
    &::placeholder {
      color: $lightSilver !important;
    }
  }
  .ant-input-group-addon {
    font-weight: bold;
    border: none !important;
    box-shadow: none !important;
  }
  .ant-checkbox-wrapper {
    .ant-checkbox-disabled {
      .ant-checkbox-inner {
        background-color: $white !important;
      }
    }
    .ant-checkbox-checked.ant-checkbox-disabled {
      .ant-checkbox-inner {
        background-color: $ash !important;
      }
    }
  }
  .ant-select-disabled {
    .ant-select-selection {
      background-color: $white !important;
      border: none !important;
      color: $navy !important;
      &__placeholder {
        color: $lightSilver !important;
      }
    }
    .ant-select-arrow {
      display: none !important;
    }
  }
}
