@import '../../shared/styles/colors.scss';

.products-container {
  .products {
    width: 100%;
    &__text-form-item {
      @include font(sm-med);
      padding-top: 5px;
      white-space: nowrap;
    }
    &__title {
      @include font(h4);
      font-weight: 700;
      position: relative;
      margin-bottom: -6px;
      &:nth-child(n + 1) {
        padding-top: 2px;
      }
      .loading-text {
        @include font(b);
        z-index: 2;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        font-size: 16px;
        color: $bourbon;
        display: block;
        i {
          margin-left: 10px;
          color: $novaBlue;
          font-size: 20px;
        }
      }
    }
    &__subtitle {
      @include font(b);
      font-weight: 700;
      margin-bottom: -6px;
    }
    &__subsubtitle {
      @include font(sm-bold);
      width: 100%;
      font-weight: 700;
      margin-bottom: -6px;
    }
    &__subsection {
      & .contaminants {
        display: flex;
        align-items: flex-end;
        .ant-form-item {
          width: 90px;
          margin-right: 16px;
          margin-bottom: 0;
          &:last-child {
            margin-right: 0;
          }
        }
        .contaminants-select-box {
          display: flex;
          width: 100%;
          margin-left: 16px;
          .right {
            margin-left: 16px;
            margin-top: 6px;
            h6 {
              @include font(h6);
              margin-bottom: 8px;
            }
            .clickable {
              @include font(e14);
              font-weight: 600;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              &:last-child {
                margin-bottom: 4px;
              }
              &:hover {
                cursor: pointer;
              }
              .icon-component {
                margin-right: 6px;
              }
            }
          }
        }
      }
            
      &__form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        width: 609px;
        input {
          padding-right: 0;
          border: 1px solid $cloud;
          height: 28px;
        }
        span {
          height: 26px;
          line-height: 26px;
        }
        &.ant-form.version-inactive {
          .ant-form-item-control.has-error {
            .ant-input.ant-input-disabled.disabled-input {
              border: none !important;
            }
            .ant-form-explain {
              display: none;
            }
          }
          .ant-input.ant-input-disabled {
            background-color: $white !important;
            border: none !important;
            color: $navy !important;
            &::placeholder {
              color: $lightSilver;
            }
          }
          .ant-input-wrapper {
            .ant-input-group-addon {
              border: none;
              background-color: $white;
              font-weight: bold;
            }
          }
          .ant-select-disabled {
            .ant-select-selection {
              background-color: $white !important;
              border: none !important;
              color: $navy !important;
            }
            .ant-select-arrow {
              display: none !important;
            }
          }
        }
        &.dimensions {
          display: flex;
          flex-wrap: nowrap;
          margin-bottom:4px;
          .ant-form-item {
            width: auto;
            max-width: 140px;
            margin-right: 16px;
            margin-bottom: 0;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        &.details {
          display: flex;
          .ant-form-item {
            width: 100%;
            max-width: 140px;
            margin-right: 16px;
            &:nth-child(4) {
              margin-right: 0;
            }
            &-fullwidth {
              width: 100%;
              max-width: 100%;
              margin-bottom: 0;
            }
          }
        }
        &.conditions {
          .ant-form-item {
            margin-bottom: 16px;
          }
        }
        &.contaminants {
          display:-webkit-box;
          width: 440px;
        }
      }


      &.general {
        display: flex;
        .ant-form-item {
          width: 140px;
          margin-right: 16px;
        }
      }
      &.environment {
      }
    }
  }

  .ant-form-item-label {
    line-height: 25px;
    textarea {
      margin-top: 4px;
    }
  }

  .ant-form-item {
    textarea {
      margin-top: 4px;
    }
  }

  .auto-rotate-button {
    position: absolute;
    top: 63px;
    right: 291px;
  }

  .text {
    @include font(sm);
    color: $ash;
  }

  .box-wrapper {
    margin-left: 24px;
    display: flex;
    width: 100%;
    cursor: all-scroll;
    .box-container {
      width: 100%;
      height: 100%;
      .width,
      .length,
      .height {
        @include font(sm);
        color: $ash;
        font-size: 10px;
        line-height: 22px;
      }
      .threejs-direction-text {
        @include font(sm-bold);
        color: $novaBlue;
        font-size: 10px;
        font-weight: 700;
        line-height: 28px;
        vertical-align: middle;
      }
    }
  }
}

.products {
  &-top {
    position: relative;
    &__inner {
      // display: flex;
      // align-items: flex-end;
      padding-bottom: 14px;
    }
    &::after {
      display: block;
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      margin-left: -24px;
      width: calc(100% + 48px);
      height: 1px;
      background-color: $cloud;
    }
  }
  &-radio__container {
    min-width: 100px;
    margin-right: 24px;
    .ant-radio-group {
      height: 40px;
      display: flex;
      align-items: center;
    }
  }
}

.product {
  &-container {
    display: flex;
    position: relative;
  }
}

.add-a-product {
  @include font(sm-med);
  color: $ash;
  width: 100%;
  border: 2px dashed $ash;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 10px;
  margin: 16px 0;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    border-color: $darkAsh;
    color: $darkAsh;
  }
  .icon-component {
    margin-right: 5px;
  }
}
