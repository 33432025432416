@import '../../shared/styles/colors.scss';

#loading-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.801);
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .loading-message {
    @include font(h5);
    color: $silver;
    margin-top: 20px;
    font-weight: 700;
  }
}
