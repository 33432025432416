@import '../../../../../shared/styles/colors.scss';

.ant-tabs-tabpane {
  .ant-form-item-control-wrapper {
    .ant-form-item-control.has-error {
      .ant-form-item-children {
        .ant-input.ant-input-disabled {
          border: none !important;
        }
        .ant-form-explain {
          display: none;
        }
      }
    }
    .ant-input.ant-input-disabled {
      background-color: $lightCloud !important;
    }
  }
}
