@import '../../../../shared/styles/colors.scss';

.select-version {
  width: 1000px;
  padding: 24px;
  &__top-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    h1 {
      @include font(h3);
      color: $novaBlue;
    }
  }
  &__right-container {
    display:flex;
    button {
      margin-right:30px;
    }
    .tm-button.small{
      padding: 0px 25px;
    }
}
}
