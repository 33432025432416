@import '../../styles/colors.scss';
.tm-button {
  font-family: Eurostile;
  border: none;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 24px;
  padding: 13px 35px;
  outline: none;
  min-width: fit-content;
  user-select: none;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  &.extra-small {
    height: 30px;
    font-size: 16px;
    font-weight: bold;
    padding: 2px 6px;
    letter-spacing: 0.8px;
  }
  &.small {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: 24px;
    padding: 4px 25px;
  }
  &.disabled {
    background-color: $ash !important;
    cursor: not-allowed;
    &:hover {
      background-color: $ash;
    }
  }
  &.danger {
    background-color: $negative;
    &:hover {
      background-color: $white;
      box-shadow: inset 0px 0px 0px 3px $negative;
      color: $negative;
    }
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &__primary {
    background: $novaBlue;
    color: $white;
    &:hover,
    &:focus {
      background-color: $cobalt;
    }
    &:focus-visible {
      box-shadow: 0 0 0 2px $deepNavy;
    }
  }
  &__secondary {
    background: $white;
    color: $novaBlue;
    box-shadow: inset 0px 0px 0px 3px $novaBlue;
    &:hover,
    &:focus {
      color: black;
      box-shadow: inset 0px 0px 0px 3px black;
      .icon-component {
        svg {
          fill: black !important;
        }
      }
    }
    &.danger {
      box-shadow: none;
      color: $white;
    }
    .icon-component {
      svg {
        fill: $novaBlue;
      }
    }
    &.disabled {
      background-color: $ash;
      box-shadow: none;
      color: $white;
      .icon-component {
        svg {
          fill: $white !important;
        }
      }
    }
  }
  &__tertiary {
    line-height: 1;
    color: $navy;
    font-weight: 700;
    border: 1px solid $ash;
    border-radius: 2px;
    font-size: 14px;
    width: fit-content;
    padding: 4px 8px;
    background-color: #fff;
    &:hover,
    &:focus {
      background-color: $lightCloud;
    }
  }
  &__icon {
    .icon-component,
    .anticon {
      font-size: 22px;
      width: 22px;
      margin-right: 8px;
    }
  }
}
