@import '../../../../shared/styles/colors.scss';
.ext-users-table {
  table {
    width: 100%;
    thead {
      th {
        font-weight: bold;
      }
    }
    tbody {
      // position: relative;
      @include font(sm);
      // .ant-table-row {
      //   &:hover, :active, :focus {
      //     background-color: $novaBlue !important;
      //     td {
      //       background-color: $novaBlue !important;
      //       color: $white;
      //       &.ant-table-row-expand-icon-cell {
      //         color: $navy;
      //         font-weight: normal;
      //       }
      //       svg {
      //         color: white !important;
      //       }
      //     }
      //   }
      // }
      .icon-component {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.ant-dropdown-menu-item.delete-user {
  @include font(sm-med);
  color: $negative;
}
