@import '../../shared/styles/colors.scss';
.version-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .conveyor-container {
    width: 100%;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: $cloud;
    min-height: 85vh;
    padding-top: 12px;
    .add-a-conveyor {
      @include font(sm-med);
      color: $ash;
      width: 100%;
      border: 2px dashed $ash;
      display: flex;
      align-content: center;
      justify-content: center;
      padding: 14px 16px;
      margin: 5px 0 10px 0;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        border-color: $darkAsh;
        color: $darkAsh;
      }
      .icon-component {
        margin-right: 5px;
      }
    }
    [data-react-beautiful-dnd-draggable]:focus-visible {
      box-shadow: 0 0 0 2px $novaBlue;
    }
  }
}
