.supports-table {
    table {
        tr {
            th {
                padding:3px 10px;
            }
        }
    }
    .ant-table-tbody {
        .ant-table-row {
            td {
                padding:3px 10px;
            }
        }
    }
}