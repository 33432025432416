@import '../../../../shared/styles/base.scss';
@import './MaterialAccessoryItem.scss';

.default-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $cloud;
  position: relative;
  padding-top: 3px;
  padding-bottom: 3px;
  &--clickable {
    cursor: pointer;
  }
  .tm-radio {
    margin: 0;
    .ant-radio-group {
      margin: 0;
    }
  }
  .ant-checkbox-wrapper {
    margin-right: 20px;
  }
  &__name {
    @include font(h5);
    font-weight: bold;
    margin-right: 10px;
    position: absolute;
    left: 70px;
  }
  &__status {
    display: flex;
    position: absolute;
    left: 220px;
    &-string {
      @include font(sm);
      color: $ash;
      margin-left: 7px;
    }
  }
  .expandbutton {
    position: absolute;
    right: 0;
    bottom: 3px;
    padding-bottom: 12px;
  }
  .left-label {
    @include font(tiny);
    font-weight: bold;
    margin-right: 3px;
    position: absolute;
    bottom: 3px;
    left: 3px;
  }
  .right-label {
    @include font(tiny);
    font-weight: bold;
    margin-right: 3px;
    position: absolute;
    bottom: 3px;
    left: 39px;
  }
  .reset-defaults {
    display: flex;
    position: absolute;
    right: 30px;
    bottom: 10px;
    @include font(sm-bold);
    color: $novaBlue;
    .icon-component {
      margin-right: 5px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
