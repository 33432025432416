@import '../../../../../../../shared/styles/colors.scss';

.builder-tile {
  position: relative;
  cursor: default;
  &__collapsed {
    margin-bottom:4px;
    .collapsed-main {
      display: flex;
      align-items: center;
      &__plusminus {
        cursor: pointer;
        margin-left: 4px;
        padding: 0;
        &:focus-visible {
          box-shadow: 0 0 0 2px $deepNavy;
        }
      }
      &__title {
        @include font(h5);
        margin-left: 10px;
        width: 85%;
        font-weight: 800;
        display: flex;
        &-overridemsg {
          margin-left: 16px;
          font-weight: 400;
          color: $ash;
          font-family: 'Eurostile';
        }
        &-copybtn {
          margin:2px 0px 2px auto;
          padding: 2px 8px
        }
      }
      &__edit {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &_text {
          margin: 0 0 3px 5px;
          @include font(sm-bold);
          color: $novaBlue;
        }
      }
      &__drag {
        position: absolute;
        top: -7px;
        right: 0;
      }
    }
    .collapsed-sub {
      margin-left: 35px;
      &__status {
        display: flex;
        align-items: center;
        &_text {
          margin-left: 5px;
          @include font(sm);
          color: $ash;
          .section {
            &.failure {
              color: $negative;
            }
          }
          line-height: 25px;
        }
      }
    }
  }
  &__expanded {
    margin-top:-10px;
    margin-bottom:10px;
    &__content {
      .material-accessory-item {
        .ant-form-item-control-wrapper {
          width: auto !important;
        }
      }
      &__wrapper {
        padding: 0px 4px;
        & & {
          padding: 0px 4px;
          background-color: rgba(0, 0, 0, 0.02);
        }
        .ant-row {
          margin: 0;
          .ant-form-item-label {
            label {
              @include font(sm-bold);
              color: $novaBlue;
              font-weight: 600;
            }
          }
          .ant-form-item-control-wrapper {
            .ant-form-explain {
              width: 560px;
            }
          }
        }
        .option-display {
          padding-top: 5px;
          display:flex;
          align-items: center;
        }
        .option-title {
          padding-right: 10px;
          @include font(sm-bold);
          color: $novaBlue;
        }
        .option-label {
          width: 100%;
          @include font(sm-bold);
          color: $novaBlue;
          .ant-form-item-required {
            color: $novaBlue;
          }
          font-weight: 600;
        }
        .option-content {
          .ant-select {
            width: 330px;
            margin: 0;
            .ant-select-selection {
              width: 100%;
              @include font(sm);
              .ant-select-selection__placeholder {
                color: $ash;
              }
              .ant-select-selection-selected-value {
                color: $navy;
              }
              .ant-select-arrow {
                i {
                  svg {
                    color: $navy;
                  }
                }
              }
            }
          }
        }
        .builder-radiogroup {
          display: flex;
          flex-wrap: wrap;
          width: 580px;
          .builder-radio {
            margin-right: 20px;
            .ant-radio-wrapper {
              .ant-radio {
                display: inline-block;
                .ant-radio-inner {
                  display: inline-block;
                  border: 2px solid $darkAsh;
                }
                &.ant-radio-checked {
                  .ant-radio-inner {
                    border: 2px solid $novaBlue;
                    &::after {
                      top: 2px;
                      left: 2px;
                      background-color: $novaBlue;
                    }
                  }
                }
              }
              span {
                display: contents;
              }
            }
            &__label {
              @include font(sm);
              display: inline-block;
              margin-left:10px
            }
            &__image {
              margin-left: 15px;
              margin-right: 15px;
              width: 157px;
              &.square {
                width: 140px;
              }
              &.selected {
                border: 3px solid $novaBlue;
              }
              img {
                width: 100%;
                height: 80px;
              }
            }
          }
        }
        .straight-three-inputs {
          display: flex;
          .ant-input-group-wrapper {
            padding-right: 10px;
            .ant-input-wrapper.ant-input-group {
              input {
                width: 100px;
              }
            }
          }
        }
      }
      &__save-delete {
        margin: 12px 4px 4px 4px;
        .delete-section {
          cursor: pointer;
          display: flex;
          align-items: center;
          .text {
            @include font(sm-med);
            color: $negative;
            margin: 0 8px;
          }
        }
      }
    }
  }
  &.disabled {
    .ant-form-item-control-wrapper {
      .ant-form-item-control.has-error {
        .ant-input.ant-input-disabled.disabled-input {
          border: none !important;
        }
        .ant-form-explain {
          display: none;
        }
      }
      .ant-input.ant-input-disabled {
        background-color: $white !important;
        border: none !important;
        color: $navy !important;
        &::placeholder {
          color: $lightSilver;
        }
      }
      .ant-input-group-addon {
        background-color: $white !important;
        font-weight: bold !important;
        border: none !important;
        box-shadow: none !important;
      }
      .ant-select-disabled {
        .ant-select-selection {
          background-color: $white !important;
          border: none !important;
          color: $navy !important;
        }
        .ant-select-arrow {
          display: none !important;
        }
      }
      .ant-radio-wrapper-disabled {
        span {
          @include font(sm);
          color: $silver;
          .ant-radio-inner {
            &::after {
              background-color: $ash !important;
            }
          }
        }
        .builder-radio__label {
          color: $silver;
        }
      }
      .ant-radio-wrapper-checked {
        .builder-radio__label {
          color: $navy;
        }
      }
      .ant-checkbox-wrapper {
        .ant-checkbox-disabled {
          .ant-checkbox-inner {
            background-color: $white !important;
          }
        }
        .ant-checkbox-checked.ant-checkbox-disabled {
          .ant-checkbox-inner {
            background-color: $ash !important;
          }
        }
      }
    }
  }
  .helical-bend {
    @include font(sm-med);
    color: $bourbon;
    position: absolute;
    top: 18px;
    left: 213px;
  }
  .max-recommended-incline {
    @include font(sm-med);
    color: $bourbon;
    position: absolute;
    top: 18px;
    left: 213px;
  }
  .caution-sections {
    @include font(sm-med);
    color: $bourbon;
  }
  .warning-sections {
    @include font(sm-med);
    color: $negative;
  }
}
