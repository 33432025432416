@import '../../../shared/styles/colors.scss';

.parts-table {
  margin-top: 5px;
  .ant-table-body {
    overflow-x: auto;
    table {
      thead {
        margin: 0;
        padding: 0;
        background-color: $cloud;
        tr {
          th {
            @include font(sm-bold);
            white-space: nowrap;
            padding: 3px 10px;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 0px 10px;
            height: 30px;
            .wrapper {
              display: flex;
            }
          }
        }
      }
    }
  }
}
