@import '../../../../shared/styles/colors.scss';

.customer-select-dropdown {
  .anticon.anticon-loading {
    color: $novaBlue !important;
    font-size: 17px;
    position: absolute;
    top: -2px;
    right: 0;
  }
}
