@import '../../../shared/styles/base.scss';

.collapse-section {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $cloud;
  position: relative;
  padding: 4px 8px;
  &--clickable {
    cursor: pointer;
  }
  .tm-radio {
    margin: 0;
    .ant-radio-group {
      margin: 0;
    }
  }
  .ant-checkbox-wrapper {
    margin-right: 20px;
  }
  &__name {
    @include font(h5);
    font-weight: bold;
    display: inline-flex;
    &-after {
      margin-left: 16px;
      display: inline-flex;
      color: $darkAsh;
      font-weight: 400;
      font-family: 'Eurostile';
      .icon-component {
        margin-right: 6px;
      }
    }
  }
  &__inner {
    background-color: rgba(0, 0, 0, 0.02);
    padding: 4px 8px;
    border-bottom: 1px solid $cloud;
  }
  &__expand {
    // margin-left: auto;
    margin-right: 16px;
  }
  &__actions {
    margin-left: auto;
  }
}
