@import '../../../../shared/styles/colors.scss';
#update-signature {
  width: 600px;
  height: auto;
  padding-block: 1.4rem;
  position: relative;
  overflow: auto;
  position: relative;
  .update-signature {
    &__title {
      @include font(h3);
      color: $novaBlue;
      border-bottom: 1.5px solid $cloud;
      padding: 20px;
      margin: 0;
      p.error {
        @include font(sm);
        color: $negative;
        font-weight: normal;
        margin: 0;
      }
    }
    &__close {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    &__signature {
      margin: 20px;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 300px;
      max-height: 300px;
    }
  }
}
