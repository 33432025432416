@import '../../../../shared/styles/colors.scss';
#import-cost-other-envs {
    width: 1000px;
    padding: 14px;
    position: relative;
    .import-cost-other-envs {
        &__header {
          @include font(h3);
          color: $novaBlue;
          margin-bottom:6px;
          p.error {
            @include font(sm);
            color: $negative;
            font-weight: normal;
            margin: 0;
          }
        }
        &__section {
            .tm-select {
                width:200px;
            }
            .ingestbtn {
                position:absolute;
                right:14px;
            }
            .displayresult {
                min-height: 300px;
            }
            h2 {
              @include font(h4);
              font-weight: 900;
              margin:0px;
            }
            width:100%
          }      
    }
    .icon-component {
        position: absolute;
        right: 20px;
        top: 20px;
    }
    .ant-table-thead {
        tr {
            th {
                padding:2px 6px
            }
        }
    }
    .ant-table-tbody {
        .ant-table-row {
            td {
                padding:2px 6px
            }
        }
    }
    .ant-table-wrapper {
        height: 100%;
      
        .ant-spin-nested-loading {
          height: 100%;
      
          .ant-spin-container {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
      
            .ant-table {
              flex: auto;
              overflow: hidden;
      
              .ant-table-container {
                height: 100%;
                display: flex;
                flex-flow: column nowrap;
      
                .ant-table-header {
                  flex: none;
                }
      
                .ant-table-body {
                  flex: auto;
                  overflow: scroll;
                }
              }
            }
      
            .ant-table-pagination {
              flex: none;
            }
          }
        }
      }
}  