@import 'shared/styles/colors.scss';

.chain-preview {
  width: 85vw;
  &__top-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 16px 24px;
    border-bottom: 1px solid $cloud;
    h1 {
      @include font(h3);
      margin: 0;
      color: $novaBlue;
    }
  }
  &__bottom-container {
    height: 80vh;
    overflow-y: auto;
    padding: 10px;
    .no-rows {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
      @include font(h4);
      color: $silver;
    }
  }
  &__row-container {
    display: flex;
    justify-content: center;
    .link {
      width: 52px;
      height: 52px;
      &.placeholder {
        border: 2px dashed $ash;
      }
    }
  }
}
