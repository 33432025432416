@import '../../../../shared/styles/colors.scss';
.gearmotor-container {
  .subtitle {
    @include font(h5);
    font-weight: 900;
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-top: 30px;
  }
  .inputs {
    display: flex;
  }
  .checkbox-list {
    height: 300px;
    overflow: auto;
    border: 2px solid $cloud;
    :nth-of-type(even) {
      background-color: $lightCloud;
    }
    &__item {
      &.tm-checkbox {
        padding: 0 15px;
        border: 1px solid $cloud;
      }
    }
  }
  &.disabled {
    .ant-form-item-control.has-error {
      .ant-form-item-children {
        .ant-input.ant-input-disabled {
          border: none !important;
        }
      }
      .ant-form-explain {
        display: none;
      }
    }
    .ant-input.ant-input-disabled {
      background-color: $white !important;
      border: none !important;
      color: $navy !important;
      &::placeholder {
        color: $lightSilver;
      }
    }
    .ant-select-disabled {
      .ant-select-selection {
        background-color: $white !important;
        border: none !important;
        color: $navy !important;
      }
      .ant-select-arrow {
        display: none !important;
      }
    }
    .ant-radio-wrapper-disabled {
      span {
        @include font(sm);
        color: $silver;
        .ant-radio-inner {
          &::after {
            background-color: $ash !important;
          }
        }
      }
    }
    .ant-radio-wrapper-checked {
      span {
        color: $navy !important;
      }
    }
    .ant-checkbox-wrapper {
      .ant-checkbox-disabled {
        .ant-checkbox-inner {
          background-color: $white !important;
        }
      }
      .ant-checkbox-checked.ant-checkbox-disabled {
        .ant-checkbox-inner {
          background-color: $ash !important;
        }
      }
    }
  }
  .tm-select {
    margin-bottom: 4px;
  }
}
.gm-meta-info {
  @include font(sm-med);
  div {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }
  span {
    flex-basis: 180px;
    &:last-child {
      flex-basis: auto;
      @include font(sm);
      font-weight: 400;
      color: $darkAsh;
    }
  }
}
