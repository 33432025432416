@import 'src/shared/styles/colors.scss';

.pnp {
  width: 100%;
  background-color: $lightCloud;
  min-height: calc(100vh - 75px);

  > section {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    margin: 40px auto;
    display: flex;
    align-items: flex-end;

    h1 {
      @include font(h1);
      color: $novaBlue;
      margin-bottom: 0;
    }

    .tm-button {
      margin-left: 20px;
    }
  }

  &__body {
    padding: 32px;
    width: 832px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 8px 0.1px rgba(23, 50, 97, 0.1);
    &__section {
      width: 100%;
      &-title {
        @include font(h4);
        font-size: 25px;
        font-weight: bold;
      }
    }
    &__actions {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    &__action-btn {
      &:first-child {
        margin-right: 20px;
      }
    }
    &-input {
      margin-bottom: 4px;
    }
    &-container {
      .ant-form-extra {
        @include font(tiny);
        line-height: 1;
        font-weight: bold;
        color: $darkAsh;
      }
    }
  }
}
