@import '../../../../shared/styles/colors.scss';
#project-list {
  background-color: $lightCloud;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 17px 0;
  width: 100%;
  max-width: 2500px;
  overflow: auto;
  p {
    @include font(b);
    margin-top: 20px;
    color: $ash;
  }
}
