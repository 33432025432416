@import '../../../../shared/styles/base.scss';
@import './MaterialAccessoryItem.scss';

.overrides-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $ash;
  position: relative;
  &--clickable {
    cursor: pointer;
  }
  .tm-radio {
    margin: 0;
    .ant-radio-group {
      margin: 0;
    }
  }
  .ant-checkbox-wrapper {
    margin-right: 10px;
    &.single-checkbox {
      margin-left: 26px;
    }
  }
  &__name {
    @include font(sm);
    font-weight: bold;
    margin-right: 10px;
    position: absolute;
    left: 50px;
  }
  &__status {
    &-string {
      position: absolute;
      left: 264px;
      @include font(sm);
      color: $ash;
      margin-left: 7px;
    }
  }
  .expandbutton {
    position: absolute;
    right: 0;
    bottom: 3px;
    padding-bottom: 4px;
  }
  .left-label {
    @include font(tiny);
    font-weight: bold;
    margin-right: 3px;
    position: absolute;
    bottom: 3px;
    left: 3px;
  }
  .right-label {
    @include font(tiny);
    font-weight: bold;
    margin-right: 3px;
    position: absolute;
    bottom: 3px;
    left: 29px;
  }
}
