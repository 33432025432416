@import '../../../../shared/styles/colors.scss';

.overview-card {
  height: 158px;
  width: 276px;
  border-radius: 4px;
  box-shadow: 0 1px 8px 0.1px rgba(23, 50, 97, 0.1);
  background-color: $white;
  padding: 20px;
  &__top {
    display: flex;
    justify-content: space-between;
    &__left {
      &__label {
        @include font(b);
      }
      &__value {
        @include font(h1);
        span {
          font-size: 27px;
          vertical-align: text-top;
        }
      }
    }
    &__right {
      &__icon-container {
        width: 72px;
        height: 72px;
        border-radius: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $novaBlue;
      }
    }
  }
  &__bottom {
    margin-top: 8px;
    &__text {
      @include font(sm);
      .icon-component {
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
}
