.grabzone {
  display: grid;
  cursor: grabbing;
  &--x {
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 50% 50%;
    width: 70px;
    height: 25px;
  }
  &--y {
    padding: 10px 0 10px 0;
    grid-template-columns: 50% 50%;
    grid-template-rows: 25% 25% 25% 25%;
    height: 70px;
    width: 25px;
  }
}
