@import '../../styles/colors.scss';

.ant-table-thead {
  tr {
    th {
      border-left: none;
      border-right: none;
      @include font(sm);
      color: $navy;
      font-weight: bold !important;
      line-height: 28px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    td:first-child {
      text-align: left;
    }
  }
}
.ant-table-tbody {
  position: relative;
  .ant-form-item {
    text-align: left !important;
  }
  .ant-select {
    margin-left: 0;
  }
  .ant-table-row {
    td {
      padding: 11px 16px;
      .ant-form-item {
        text-align: center;
      }
      .tm-input.small.validation-input {
        .input-wrapper {
          display: flex;
          justify-content: center;
          height: 32px;
          input {
            text-align: right;
          }
        }
      }
    }
  }
}
.editable-cell-value-wrap {
  padding-right: 0 !important;
  display: inline;
  .input-wrapper {
    display: flex;
    height: 32px;
    justify-content: center;
    input {
      border-radius: 4px;
    }
  }
}
