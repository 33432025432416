@import '../../styles/colors';
.tm-checkbox {
  &__label {
    @include font(sm);
    margin-left: 5px;
  }
  &__pretty-name {
    @include font(sm-bold);
    color: $novaBlue;
  }
  .ant-form-item {
    margin: 0 !important;
  }
  .ant-checkbox-wrapper {
    @include font(sm);
    .ant-checkbox {
      border-radius: 3px;
      .ant-checkbox-inner {
        border: 2px solid $ash;
      }
      &-checked {
        .ant-checkbox-inner {
          border: 1px solid $novaBlue;
          background-color: $novaBlue;
        }
      }
      &-disabled {
        .ant-checkbox-inner {
          border: 2px solid $darkAsh !important;
          background-color: $silver !important;
        }
      }
    }
  }
  .ant-form-extra {
    @include font(e14);
    color: $ash;
    padding-top: 0;
    margin-top: -6px;
  }
}
