@import '../../../../shared/styles/colors.scss';
#project-status-pie-chart {
  position: relative;
  box-shadow: 0 1px 8px 0.1px rgba(23, 50, 97, 0.1);
  border-radius: 4px;
  width: 577px;
  height: 347px;
  padding: 20px;
  .projects-by-status {
    @include font(b);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tm-button {
      padding: 3px 15px;
    }
  }
  .project-status-pie-chart {
    &__pie {
      position: absolute !important;
      left: 20px;
      top: 65px;
      .recharts-surface {
        overflow: visible;
      }
      .recharts-legend-wrapper {
        width: 250px !important;
        transform: translate(290px, -10px);
        @include font(tiny);
        .recharts-legend-item {
          margin-top: 20px;
          &-text {
            font-size: 14px;
          }
          .recharts-surface {
            vertical-align: baseline !important;
            margin-right: 8px !important;
          }
          margin-right: 12px !important;
        }
      }
      &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 150px;
        left: 105px;
        .title {
          @include font(sm);
        }
        .count {
          @include font(h1);
          font-size: 42px;
        }
        .total-cost {
          @include font(sm-bold);
        }
      }
    }
    &__tooltip {
      padding: 8px 12px;
      min-width: 140px;
      border-radius: 4px;
      background-color: rgba(23, 50, 97, 0.75);
      box-shadow: 0 1px 15px 0.2px rgba(23, 50, 97, 0.2);
      @include font(sm);
      color: $white;
      &__header {
        font-weight: bold;
      }
      &__content {
        margin: 0;
        display: flex;
        align-items: center;
      }
    }
  }
}
