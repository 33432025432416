@import 'src/shared/styles/colors.scss';

#inline_edit {
    display: flex;
    flex-direction: column;
    .text--red {
        font-size: 12px;
        font-weight: normal;
        color: $negative;
      }  
}