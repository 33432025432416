@import '../../../../shared/styles/colors.scss';

#statistics-chart {
  width: 100%;
  height: 780px;
  padding: 20px;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0 1px 8px 0.1px rgba(23, 50, 97, 0.1);
  .statistics-chart {
    &__chart-select-dropdown {
      position: absolute;
      right: 0;
    }
    &__line {
      .recharts-surface {
        overflow: visible;
      }
      .recharts-legend-wrapper {
        transform: translate(10px, 50px);
        @include font(tiny);
      }
      .recharts-text {
        @include font(sm);
        fill: $navy;
      }
    }
    &__title {
      @include font(b);
      margin-bottom: 35px;
    }
    &__tooltip {
      padding: 8px 12px;
      min-width: 140px;
      border-radius: 4px;
      background-color: rgba(23, 50, 97, 0.75);
      box-shadow: 0 1px 15px 0.2px rgba(23, 50, 97, 0.2);
      @include font(sm);
      color: $white;
      &__header {
        font-weight: bold;
      }
      &__content {
        margin: 0;
        display: flex;
        align-items: center;
        &__circle {
          width: 8px;
          height: 8px;
          border-radius: 8px;
          margin-right: 5px;
          border: 1px solid $white;
        }
      }
    }
  }
}
