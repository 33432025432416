.conveyorbuilder {
  &__container {
    display: flex;
  }
  &__top-section {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  .buttons-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 6px;
    justify-content: space-between;
    .tm-pending {
      position: absolute;
      left: 270px;
    }
  }
}
