@import '../../shared/styles/colors.scss';

$sectionSize: 434px;
$sectionXMargin: 100px;
$rightSideSize: $sectionSize + ($sectionXMargin * 2);

.login {
  &-container {
    background-color: $white;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  &-left {
    flex: 1 1 50%;
    height: 100vh;
    &__background {
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }
    &__logo {
      position: absolute;
      top: 40px;
      left: 40px;
      width: 280px;
    }
  }
  &-right {
    background: #fff;
    flex: 1 1 50%;
    &__logo {
      display: block;
      margin: 40px auto 8px;
      width: 120px;
    }
    &__container {
      max-width: $sectionSize;
      margin: 0 auto;
      @media (min-width: #{$rightSideSize * 2}) {
        margin-left: $sectionXMargin;
        margin-right: 0;
      }
    }
    &__heading {
      @include font(h2);
      color: $novaBlue;
    }
    &__subheading {
      font-family: 'Museo-Sans';
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.1px;
      text-transform: uppercase;
      color: $bourbon;
      font-weight: bolder;
    }
    &__subsub {
      font-family: 'Eurostile';
      font-size: 18px;
      font-weight: 900;
      line-height: 20px;
      text-transform: uppercase;
      color: $ash;
    }
    &__signuploginlinks {
      display:flex
    }
    &__submit {
      margin-top: 8px;
    }
    &__switch {
      padding: 0px 5px;
      color: $novaBlue;
      font-weight: bold;
      cursor: pointer;
      
      a {
        transition: none;
        &:hover {
          color: $darkAsh;
          text-decoration: underline;
          text-decoration-thickness: 2px;
          color: $novaBlue;
          .icon-component {
            path,
            svg {
              fill: $novaBlue;
            }
          }
        }
      }
    }
    .ant-input {
      width: 400px;
      height: 54px;
      margin-bottom: 16px;
      background-color: $cloud;
      border: none;
      padding: 0 10px;
      &:nth-last-of-type() {
        margin-bottom: 24px;
      }
    }
    &__form {
      margin-bottom: 40px;
      &-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &-signup {
        .ant-input {
          margin-bottom: 2px;
        }
      }
    }
    label {
      @include font(sm-bold);
      color: $novaBlue;
      margin-bottom: 4px;
    }
    .forgot {
      @include font(b);
      color: $ash;
      margin-bottom: 16px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &-link {
    color: $novaBlue;
    font-size: 14px;
    text-decoration: underline;
    &:hover {
      color: $navy;
      text-decoration: underline;
    }
    &:focus {
      color: $navy;
      outline: auto $navy;
    }
  }
}
