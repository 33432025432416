@import '../../../../shared/styles/colors.scss';

#project-summary {
  width: 100%;
  box-shadow: 0 1px 3.96px 0.04px rgba(71, 40, 155, 0.1);
  padding: 12px 40px;
  .expired-quote {
    position: relative;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 1px 3.96px 0.04px rgba(71, 40, 155, 0.1);
    margin-top: 4px;
    margin-bottom: 24px;
    &__text {
      padding: 10px 48px;
      @include font(sm);
    }
    .icon-component {
      position: absolute;
      top: 12px;
      left: 15px;
    }
  }
  .project-summary {
    &__firstrow {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      &__plus-minus {
        margin-right: 10px;
        &:hover {
          cursor: pointer;
        }
        &:focus-visible .icon-component {
          box-shadow: 0 0 0 2px $deepNavy;
        }
      }
      &__name {
        @include font(h2);
        color: $novaBlue;
        margin-right: 16px;
        max-width: 360px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &__number {
        @include font(sm);
        line-height: 34px;
      }
      &__version-number {
        @include font(sm-med);
        line-height: 21px;
        width: fit-content;
        height: 28px;
        color: $white;
        background-color: $novaBlue;
        border-radius: 2px;
        padding: 2px 6px 4px 6px;
        margin: 0 16px;
      }
      .secondary-options-container {
        border: 1px solid $ash;
        border-radius: 2px;
        display: flex;
        margin-right: auto;
      }
      &__switch-version,
      &__edit-project,
      &__collapse-conveyors,
      &__calendar {
        @include font(e14);
        padding: 4px 12px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;
        font-weight: bold;
        border-right: 1px solid $ash;
        .icon-component {
          margin-right: 2px;
        }
        &:focus-visible {
          box-shadow: 0 0 0 2px $novaBlue;
        }
      }
      &__price {
        transform: translateY(7px);
        &-validation {
          @include font(b);
          font-size: 16px;
          font-weight: 700;
          display: flex;
          .validate-price {
            padding: 4px 16px;
            margin-right: 16px;
          }
          &-status {
            @include font(b);
            font-size: 12px;
            font-weight: normal;
            margin-top: 2px;
            line-height: 1;
            width: 100%;
            text-align: right;
          }
          &-est {
            @include font(sm-bold);
            font-size: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 300px;
            .icon-component {
              margin-left: 5px;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      &__labom {
        transform: translateY(7px);
        &-generation {
          @include font(b);
          font-size: 16px;
          font-weight: 700;
          display: flex;
          &-status {
            @include font(b);
            font-size: 12px;
            font-weight: normal;
            margin-top: 2px;
            line-height: 1;
            width: 100%;
            text-align: right;
            &-invalid {
              color:$negative;
            }
          }
        }
      }
      &__project-owner {
        align-self: flex-end;
      }
      &__calendar {
        padding:0px 12px;
        border-right: none;
        position: relative;
        &.disabled {
          background-color: $lightCloud;
          color: $ash;

          .anticon.anticon-calendar.ant-calendar-picker-icon {
            color: $ash !important;
          }

          .text {
            color: $ash !important;
          }
        }
        .text {
          position: absolute;
          left: 30px;
          @include font(e14);
          font-weight: bold;
        }
        .ant-calendar-picker {
          z-index: 0;
          .ant-calendar-picker-input.ant-input {
            cursor: pointer;
            background: none !important;
            width: 250px;
            height: 28px;
            border: none !important;
            padding-left: 127px;
            padding-top: 2px;
            &.ant-input-disabled {
              color: $ash;
              cursor: not-allowed;
            }
            &:focus-visible {
              box-shadow: 0 0 0 2px $novaBlue;
            }
          }
          .anticon.anticon-calendar.ant-calendar-picker-icon {
            left: 0px;
            color: $novaBlue;
            [disabled] + & {
              color: $silver;
            }
          }
          .anticon.anticon-close-circle.ant-calendar-picker-clear {
            right: 5px;
          }
        }
      }
    }
    &__secondrow {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      &__project-status {
        @include font(b);
        font-size: 14px;
        color: $novaBlue;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 12px 16px 0 0px;
        padding: 0 8px;
        background-color: #e5f0f7;
        border-radius: 2px;
        line-height: 28px;
      }
      &__status-actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-right: 16px;
        margin-top: 12px;
        .tm-button {
          font-size: 14px;
          padding: 2px 16px;
        }
      }
      &__full-status-history {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;
        @include font(e14);
        font-weight: bold;
        border: 1px solid $ash;
        border-radius: 2px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 4px 12px;
        margin-top: 12px;
        .icon-component {
          margin-right: 4px;
        }
        &:focus-visible {
          box-shadow: 0 0 0 2px $novaBlue;
        }
      }
      &__calendar {
        margin-top: 12px;
        position: relative;
        &.disabled {
          background-color: $lightCloud;
          color: $ash;

          .anticon.anticon-calendar.ant-calendar-picker-icon {
            color: $ash !important;
          }

          .text {
            color: $ash !important;
          }
        }
        .text {
          position: absolute;
          top: 5px;
          left: 29px;
          @include font(e14);
          font-weight: bold;
        }
        .ant-calendar-picker {
          z-index: 0;
          .ant-calendar-picker-input.ant-input {
            cursor: pointer;
            background: none !important;
            width: 265px;
            height: 28px;
            border: 1px solid $ash !important;
            border-left: none !important;
            border-radius: 2px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding-left: 140px;
            padding-top: 2px;
            &.ant-input-disabled {
              color: $ash;
              cursor: not-allowed;
            }
            &:focus-visible {
              box-shadow: 0 0 0 2px $novaBlue;
            }
          }
          .anticon.anticon-calendar.ant-calendar-picker-icon {
            left: 13px;
            color: $novaBlue;
            [disabled] + & {
              color: $silver;
            }
          }
          .anticon.anticon-close-circle.ant-calendar-picker-clear {
            right: 5px;
          }
        }
      }
      &__syteline-cost-date {
        @include font(e14);
        font-weight: bold;
        padding: 12px 0 0 12px;
        span {
          font-weight: normal;
        }
      }
    }
    &__thirdrow {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 22px;
      &__download {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        .tm-select {
          margin-right: 16px;
        }
        .download-button {
          padding: 4px 16px;
        }
      }
      &-heading {
        @include font(h5);
        font-weight: 600;
        margin-bottom: 6px;
      }
      &__label {
        @include font(sm-med);
        color: $novaBlue;
      }
    }
    &__lastmodified {
      margin-bottom: 0;
      color: $navy;
      padding: 8px 0 0;

      strong {
        margin-right: 8px;
      }
    }
  }
}

.extend-30days {
  color: $novaBlue;
  font-weight: bold;
  text-align: center;
  user-select: none;
  cursor: pointer;
}
