@import '../../../../shared/styles/colors.scss';

.project-notes {
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 8px;
  height: calc(100% - 76px);
  &-toggle {
    position: fixed;
    bottom: 16px;
    right: 16px;
    padding: 14px;
    background-color: $novaBlue;
    box-shadow: 0 1px 15px 0.2px rgba(23, 50, 97, 0.2);
    border-radius: 50px;
    cursor: pointer;
    transition: transform 0.4s;
    &:hover {
      background-color: darken($novaBlue, 5);
    }

    &--active {
      transform: translateY(96px);
    }
  }
  &-display {
    position: fixed;
    z-index: 1;
    height: 50vh;
    width: 800px;
    bottom: 16px;
    right: 16px;
    max-height: calc(100vh - 32px);
    max-width: calc(100vw - 32px);
    min-height: 200px;
    min-width: 250px;
    border-radius: 4px;
    box-shadow: 0 1px 3.96px 0.04px rgba(71, 40, 155, 0.1);
    background-color: white;
    overflow: hidden;
    transition: transform 0.4s, opacity 0.25s;
    transform-origin: 92% 88%;

    &--hidden#{&}--out:not(#{&}--anim) {
      display: none;
    }
    &--in {
      opacity: 1;
      transform: none;
    }
    &--out {
      opacity: 0;
      transform: translateY(30px) scale(0);
    }

    &__header {
      display: flex;
      justify-content: space-between;
      padding: 16px 24px;

      h3 {
        @include font(h4);
        line-height: 36px;
        font-weight: bold;
        margin-bottom: 0;
      }
    }

    &__divider {
      display: block;
      height: 1px;
      width: 800px;
      background-color: $cloud;
    }
  }
  &-form {
    &.ant-form {
      // for added specificity
      padding: 0 24px;
    }
    &__cancel {
      margin-left: auto;
      margin-right: 16px;
    }
  }
  &-resizer {
    position: absolute;
    width: 20px;
    height: 20px;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      background-color: #666;
      height: 1px;
    }
    &::before {
      top: 2px;
      width: 4px;
      left: calc(50% - 2px);
    }
    &::after {
      top: 5px;
      width: 10px;
      left: calc(50% - 5px);
    }

    &--xy {
      // this is for top left only
      top: 0;
      left: 0;
      transform: rotate(-45deg);
      cursor: nw-resize;
    }
    &--y {
      top: 0;
      right: 0;
      transform: rotate(45deg);
      cursor: n-resize;
    }

    &--x {
      bottom: 0;
      left: 0;
      transform: rotate(-135deg);
      cursor: w-resize;
    }
  }
}

.project-note {
  margin: 24px 0;
  padding: 0 24px;
  &__note {
    color: $navy;
    font-family: 'Museo-Sans';
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
    &--editing {
      margin-bottom: 10px;
    }
  }
  &__meta {
    color: $navy;
    font-family: 'Eurostile';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    display: flex;
    flex-wrap: wrap;
    &-username {
      margin-right: 12px;
      flex-shrink: 0;
    }
    &-date {
      margin-right: 12px;
      flex-shrink: 0;
    }
    &-actions {
      margin-left: auto;
      display: flex;
    }
    &-action {
      display: flex;
      font-weight: 600;
      align-items: center;
      cursor: pointer;
      &--blue {
        color: $novaBlue;
      }
      &--red {
        color: $negative;
      }

      &:disabled {
        color: $ash;
        cursor: not-allowed;
      }

      .icon-component {
        margin-right: 4px;
      }
    }
  }
  &--editing {
    color: $ash;
    &.ant-skeleton {
      width: auto;
      margin-left: auto;
    }
  }

  .ant-skeleton-paragraph {
    margin-bottom: 0;
    > li {
      height: 14px;
      margin-top: 5px;
      margin-bottom: 5px;
      & + li {
        margin-top: 10px;
      }
    }
  }
}
