@import '../../../../shared/styles/colors.scss';

.estimator-modal__status-content {
  @include font('sm');

  &--success {
    color: $positive;
  }

  &--error {
    color: $negative;
  }
}