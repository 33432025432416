@import '../../../../shared/styles/colors.scss';

#statistics-table {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .statistics-table {
    &__title {
      @include font(b);
      margin-bottom: 35px;
    }
    &__legend {
      display: inline-block;
      border-radius: 4px;
      width: 8px;
      height: 8px;
      margin-right: 8px;
      &.estimates {
        background-color: $bourbon;
      }
      &.sold {
        background-color: $novaBlue;
      }
    }
    &__totals {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      .text {
        @include font(sm);
        &.first {
          margin-right: 20px;
        }
      }
    }
  }
  table {
    max-width: 1150px !important;
    thead {
      th {
        @include font(sm-bold);
        border-color: $silver;
      }
    }
    tbody {
      @include font(sm);
      .ant-table-row {
        &:hover,
        :active,
        :focus {
          background-color: $novaBlue !important;
          td {
            background-color: $novaBlue !important;
            color: $white;
            &.ant-table-row-expand-icon-cell {
              color: $navy;
              font-weight: normal;
            }
            svg {
              color: $white !important;
            }
          }
        }
        td {
          padding: 11px 7px;
          border-color: $silver !important;
          text-align: left !important;
          &.totalcost {
            text-align: right;
          }
        }
      }
      .icon-component {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
