@import '../../styles/colors.scss';

.parts-table-layout {
  width: 100%;
  min-height: calc(100vh - 75px);

  .tm-button {
    &:first-of-type {
      margin-left: auto;
    }
    &:not(:first-of-type) {
      margin-left: 20px;
    }
  }

  > section {
    width: 100%;
    padding: 0 24px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 1440px) {
      padding: 0 80px;
    }
  }

  &__top {
    margin: 40px auto;
    display: flex;
    align-items: flex-end;
    h1 {
      @include font(h1);
      color: $novaBlue;
      margin-bottom: 0;
    }
  }

  &__mid {
    margin-bottom: 40px;
  }

  .ant-table-column-sorter-inner {
    .anticon svg {
      fill: $ash;
    }
    .anticon.on {
      svg {
        fill: $deepNavy;
      }
    }
  }

  tr {
    td {
      .ant-input-affix-wrapper {
        width: auto;
      }
      .ant-input {
        width: 100px;
      }
    }
  }

  .ant-table-body {
    [vt] {
      width: 100% !important;
    }
    tr {
      td.ant-table-column-sort {
        background: rgba(0, 0, 0, 0.05);
      }
      &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) td {
        background: rgba(0, 107, 182, 0.1);
      }
    }
  }

  .spt-alert__container {
    max-width: calc(100% - 48px);
    margin: -8px auto 16px;
    @media (min-width: 1248px) {
      max-width: 1200px;
      margin-left: 24px;
    }
    @media (min-width: 1440px) {
      margin-left: 80px;
    }
  }
}
