@import 'shared/styles/colors.scss';

.project-searching {
  width: 1100px;
  display: flex;
  align-items: center;
  color: $deepNavy;
  margin-bottom: 8px;
  min-height: 106px;
  border-radius: 4px;
  font-family: 'Eurostile';
  font-size: 18px;

  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(25%, $white),
    color-stop(37%, $lightCloud),
    color-stop(63%, $white)
  );
  background: linear-gradient(90deg, $white 25%, $lightCloud 37%, $white 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1s ease infinite;
  animation: ant-skeleton-loading 1s ease infinite;

  &__dot {
    position: absolute;
    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        transform: translateX($i * 4px - 4px);
      }
    }
  }

  &__dots {
    margin-right: auto;
    display: flex;
    align-items: center;
  }

  &__meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
    max-width: 1100px;

    &-dt {
      display: block;
    }

    &-actions {
      display: block;
    }
  }
}
