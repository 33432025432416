@import 'shared/styles/module.scss';

.legend-fields {
  max-width: 645px;
  &-section__title {
    @include font(h4);
    font-weight: 700;
    margin-bottom:0px;
  }
  &.ant-form.version-inactive {
    .ant-form-item-control.has-error {
      .ant-input.ant-input-disabled.disabled-input {
        border: none !important;
      }
      .ant-form-explain {
        display: none;
      }
    }
    .ant-input.ant-input-disabled {
      background-color: $white !important;
      border: none !important;
      color: $navy !important;
      &::placeholder {
        color: $lightSilver;
      }
    }
    .ant-input-wrapper {
      .ant-input-group-addon {
        border: none;
        background-color: $white;
        font-weight: bold;
      }
    }
    .ant-select-disabled {
      .ant-select-selection {
        background-color: $white !important;
        border: none !important;
        color: $navy !important;
      }
      .ant-select-arrow {
        display: none !important;
      }
    }
  }
  &__text-form-item {
    @include font(sm-med);
    padding-top: 5px;
    white-space: nowrap;
  }
}
