@import 'shared/styles/module.scss';

.guiderails-inline {
  display: inline-block;
  margin-right: 30px;
  label {
    @include font(sm-bold);
    color: $novaBlue;
    display: inline-block;
    overflow: hidden;
    line-height: 26px;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
  }
  .ant-select-disabled {
    .ant-select-selection {
      background-color: $lightCloud !important;
      .ant-select-selection-selected-value {
        color: $navy !important;
      }
    }
    .ant-select-arrow {
      display: none !important;
    }
  }
}
.guiderails-railsfield {
  label {
    @include font(sm-bold);
    color: $novaBlue;
    line-height: 26px;
  }
  .guiderails-label {
    margin-bottom: 20px;
  }
  &.indented {
    .tm-radio {
      // padding-left: 40px;
    }
  }
  .tm-radio {
    .ant-radio-group {
      // width: 100%;
      // display: flex;
      // justify-content: space-evenly;
      // flex-wrap: wrap;
      .tm-radio-option-wrapper {
        width: 30%;
      }
    }
  }
  &.sectionoverride {
    .tm-radio {
      .ant-radio-group {
        display: block;
        .tm-radio-option-wrapper {
          width: unset;
          display: block;
        }
      }
    }
  }
}
.copy-guiderails-button {
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 4px 13px;
  border: 1px solid $ash;
  border-radius: 2px;
  background-color: $white;
  cursor: pointer;
  &__text {
    @include font(e14);
    font-weight: bold;
  }
  .icon-component {
    margin-right: 5px;
  }
}
.values-copied-to {
  display: flex;
  align-items: center;
  position: absolute;
  top: 92px;
  left: 250px;
  @include font(e14);
  font-weight: bold;
  .icon-component {
    margin-right: 9px;
  }
}
.values-copied-from {
  display: flex;
  align-items: center;
  padding: 0 18px;
  margin: 0 0 20px 0;
  height: 40px;
  max-width: 1110px;
  background-color: $white;
  box-shadow: 0 1px 3.96px 0.04px rgba(71, 40, 155, 0.1);
  .icon-component {
    margin-right: 14px;
  }
  .text {
    @include font(e14);
  }
}
.guiderails-formitem-display {
  color: $navy;
  font-family: 'Eurostile';
  font-size: 16px;
  line-height: 28px;
  transform: translateY(2px);
  display: inline-block;
}
.guiderails-bracket {
    .tm-radio-option-wrapper {
    width: 230px;
  }
}
