@import '../styles/colors.scss';
.shared-h1 {
  @include font(h1);
  margin-bottom: 15px;
}
.shared-h2 {
  @include font(h2);
  margin-bottom: 15px;
}
.shared-h3 {
  @include font(h3);
  margin-bottom: 15px;
}
.shared-h4 {
  @include font(h4);
  margin-bottom: 15px;
}
.shared-h5 {
  @include font(h5);
  margin-bottom: 15px;
}
.shared-h6 {
  @include font(h6);
  margin-bottom: 15px;
}
.shared-sm {
  @include font(sm);
  margin-bottom: 15px;
}
.shared-sm-med {
  @include font(sm-med);
  margin-bottom: 15px;
}
.shared-sm-bold {
  @include font(sm-bold);
  margin-bottom: 15px;
}
.shared-b {
  @include font(b);
  margin-bottom: 15px;
}
.shared-b-bold {
  @include font(b-bold);
  margin-bottom: 15px;
}
