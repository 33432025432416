@import '../../../../shared/styles/colors.scss';

.validate-price {
  width: 1100px;
  padding: 4px;
  &__top {
    display: flex;
    justify-content: space-between;
    margin: 4px 0 4px 0;
    h1 {
      @include font(h3);
      color: $novaBlue;
      margin-bottom: 0;
    }
    &__left {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__right {
      @include font(sm-bold);
      color: $novaBlue;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .customer-discount {
        display: flex;
        align-items: center;
        @include font(sm-bold);
        .project-discount {
          margin-left: 8px;
          width: 78px;
        }
        .tm-button {
          padding: 3px 11px;
          margin: 0 16px 0 8px;
        }
      }
    }
  }
  &__topmid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      @include font(e14);
      line-height: 24px;
      font-weight: 600;
    }
    h4 {
      @include font(sm-med);
      margin: 0;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .project-name-number {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 4px;
      position: relative;
        .project-name {
        @include font(sm-bold);
        margin: 0 20px 0 0;
        max-width: 350px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .project-number {
        display: block;
        @include font(sm);
        margin-right: 16px;
        font-weight: 500;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .version-number {
        @include font(sm-med);
        line-height: 21px;
        width: fit-content;
        height: 28px;
        color: $white;
        background-color: $novaBlue;
        border-radius: 2px;
        padding: 2px 6px 4px 6px;
        margin: 0 16px;
      }      
    }
  }
  &__middle {
    &__subtotals {
      @include font(sm-med);
      display: flex;
      justify-content: flex-end;
      margin: 0;
      div {
        margin: 0 0 0 50px;
      }
      &__label {
        font-weight: 700;
      }
    }
    .ant-input-group-wrapper {
      display: block;
      &.variable-discount__input {
        display: inline-block;
      }
    }
    .project-breakdown {
      margin-top: 25px;
      margin-bottom: 25px;
      th {
        @include font(sm-med);
        text-align: right;
        border-bottom: 1px solid $silver;
        padding: 10px 16px;
      }
      td {
        @include font(sm-med);
        text-align: right;
        border-bottom: none;
      }
    }
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // &__grand-totals {
    //   @include font(sm);
    //   font-size: 18px;
    //   display: flex;
    //   justify-content: flex-end;
    //   margin: 0 0 20px 0;
    //   div {
    //     margin: 0 0 0 50px;
    //   }
    //   &__label {
    //     font-weight: 700;
    //   }
    // }
  }
  &__export-price-button {
    display: flex;
    justify-content: flex-end;
    margin-left: 32px;
  }
  &__validate-price-button {
    display: flex;
    align-items: center;
  }

  &__invisible-container {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
    transform: translate(-10000 -10000);
  }
}
.validation-table-actions {
  display: flex;
}
