@import '../../../../shared/styles/colors.scss';
.ant-form.project-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .ant-form-item {
    &-children {
      .ant-input {
        background-color: $cloud !important;
        @include font(sm);
        border: none;
        color: $deepNavy;
        padding: 0 0 0 10px;
        line-height: 26px;
        &:focus {
          box-shadow: none;
        }
        &.small {
          height: 32px;
        }
        &.medium {
          height: 28px;
        }
        &.large {
          height: 50px;
        }
        &[disabled] {
          background-color: $lightCloud;
          color: rgba(0, 0, 0, 0.25);
        }
        &.display-text {
          @include font(b);
          font-size: 16px;
          background-color: $white !important;
          border: none !important;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .ant-select {
        @include font(sm);
        border: none;
        color: $deepNavy;
        margin: 2px 0;
        height: 28px;
        line-height: 28px;
        &:hover,
        :active,
        :focus {
          outline: none;
          box-shadow: none;
          border-color: $silver;
        }
        .ant-select-selection {
          background-color: $cloud;
          height: inherit;
          &:hover,
          :active,
          :focus {
            outline: none;
            box-shadow: none;
            border-color: $silver;
          }
          &__rendered {
            height: inherit;
          }
          &-selected-value {
            height: inherit;
          }
          .anticon.anticon-loading {
            position: absolute;
            top: -3px;
            right: 0;
            font-size: 20px;
            .anticon-spin {
              fill: $novaBlue;
            }
          }
        }
      }
    }
  }
}
.ant-select-dropdown-menu-item {
  @include font(sm);
  color: $darkAsh;
  background-color: $white !important;
  padding: 0 12px;
  &:hover {
    background-color: $novaBlue !important;
    color: $white;
  }
  &-disabled {
    background-color: $white !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }
}
