@import 'shared/styles/colors.scss';

#admin-dashboard {
  position: relative;
  .admin-dashboard {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__title {
        @include font(h1);
        color: $novaBlue;
        margin: 40px 0;
        display: flex;
        align-items: center;
      }
      &__dropdowns {
        display: flex;

        > *:nth-child(-n + 2) {
          margin-right: 16px;
        }
      }
    }
    &__container {
      width: 100%;
      &__top {
        display: flex;
        .overview-cards {
          width: 577px;
          height: 350px;
          display: grid;
          grid-template-columns: 276px 276px;
          grid-template-rows: 158px 158px;
          grid-gap: 32px;
          margin-right: 37px;
        }
      }
      &__bottom {
        .graphs-container {
          display: flex;
          justify-content: space-between;
          margin: 32px 0;
          .total-sold {
            width: 100%;
          }
        }
      }
    }
  }
}
