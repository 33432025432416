@import '../../../../shared/styles/colors.scss';

.chainbuilder-link {
  pointer-events: none;
  opacity: 0.55;
  font-size: 12px;
  &.active {
    opacity: 1;
    pointer-events: all;
  }
  .add-link-icon,
  .remove-link-icon {
    &:hover {
      cursor: pointer;
    }
    width: 20px;
    height: 20px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    top: 12px;
    right: 12px;
    background-color: $white;
    border-radius: 10px;
  }
  &.bottomlink {
    pointer-events: all;
    opacity: 1;
  }
  &.leftlink {
    display: inline-block;
    width: 44px;
    height: 44px;
    vertical-align: top;
    &.show-border {
      width: 54px;
      padding-right: 8px;
      border-right: 2px solid $ash;
    }
  }
  &.rightlink {
    display: none;
    width: 44px;
    height: 44px;
    vertical-align: top;
    margin-right: 0;
    &.show-border {
      display: inline-block;
      width: 54px;
      padding-left: 8px;
      border-left: 2px solid $ash;
      margin-right: 0;
    }
  }
  &.centerlink {
    display: inline-block;
    width: 44px;
    height: 44px;
    vertical-align: top;
  }
  .empty-side-link {
    border: 2px dashed $ash;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
  }
  position: relative;
  width: 84px;
  height: 84px;
  flex-shrink: 1;
  img {
    border-radius: 4px;
    width: 100%;
    height: 100%;
  }
  p {
    position: absolute;
    top: 0;
  }
  .delete {
    position: absolute;
    top: 5px;
    right: 5px;
    color: $negative;
    opacity: 0;
    transition: opacity 0.1s ease;
    cursor: pointer;
    &.active {
      opacity: 1;
    }
    z-index: 1200120012001200;
  }
}
.conveyor-row {
  &.fullscreen {
    .inner-container-chain {
      .chainbuilder-row {
        padding-top: 20px;
        .top-builderbox {
          width: 75%;
          min-width: unset;
        }
      }
    }
  }
}
.inner-container-chain {
  .add-row-wrapper {
    border-top: 1px solid $cloud;
    display: flex;
    align-items: center;
    justify-content: center;
    .add-row-container {
      @include font(sm-med);
      color: $ash;
      width: 100%;
      border: 2px dashed $ash;
      display: flex;
      align-content: center;
      justify-content: center;
      padding: 4px;
      margin: 4px 0;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        border-color: $darkAsh;
        color: $darkAsh;
      }
      .icon-component {
        margin-right: 5px;
      }
    }
  }
}

.chainbuilder-row {
  background: $white;
  position: relative;

  .busy-loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background: black;
    opacity: 0.6;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    .busy-loader-inner {
      text-align: center;
      h2 {
        @include font(sm-bold);
        color: $white;
      }
    }
  }

  .horizontal-list {
    display: inline-block;
    outline: none;
  }

  .chainbuilder-link {
    margin-bottom: 4px;
    margin-right: 4px;
    &.hovered {
      cursor: pointer;
    }
  }

  .chainbuilder-row-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    border-top: 1px solid $cloud;
    height: 32px;
    position: relative;
    &__left-content {
      display: flex;
      align-items: center;
      .icon-component {
        margin-left: 24px;
        margin-right: 40px;
      }
      .error-message {
        display: flex;
        @include font(sm-med);
        .icon-component {
          margin-right: 15px;
        }
      }
    }
    .ant-dropdown-trigger {
      margin-right: 15px;
      p {
        @include font(sm-med);
        position: relative;
        color: $cobalt;
      }
      i {
        font-size: 22px;
        position: absolute;
        top: 28px;
        right: 4px;
      }
    }
    .TMlink {
      margin: 0 !important;
      border-bottom: none;
      position: absolute;
      top: -12px;
      right: 30px;
    }
    .title {
      display: flex;
      align-items: center;
      @include font(h5);
      color: $navy;
      letter-spacing: -0.58px;
      font-weight: 600;
      margin: 0;
      .text {
        width: 110px;
      }
      .width-information {
        width: 200px;
        @include font(sm-med);
        color: $novaBlue;
        cursor: initial;
        span {
          margin-left: 5px;
          color: $negative;
          font-size: 16px;
          border-radius: 0px;
          background-color: $white;
          margin-right: 0px;
          padding: 0px 0px;
          transform: none;
          font-weight: 400;
        }
      }
    }
    button.tm-button--extrasmall {
      transform: translateY(-3px);
      font-size: 13px;
      margin-left: 10px;
      font-weight: 700;
    }
  }
  .chainbuilder-inner-row {
    @include font(sm);
    .rightstuff {
      display: table-cell;
      position: relative;
      padding-bottom: 20px;
      padding: 16px 25px;
      width: 346px;
      background-color: rgba(0, 0, 0, 0.02);
      .status-box {
        max-height: 500px;
        overflow: auto;
        h3 {
          @include font(h5);
          color: $navy;
          font-weight: bold;
        }
        h4 {
          @include font(sm-bold);
          color: $navy;
          &.spot-title {
            text-transform: capitalize;
          }
        }
        p,
        .info {
          @include font(sm);
          color: $ash;
        }
      }
      .buttons {
        bottom: 0;
        padding-top: 10px;
        h3 {
          @include font(sm-bold);
          color: $cobalt;
        }
        .row-repeat-container {
          display: flex;
          justify-content: space-between;
          .row-repeat-button {
            @include font(sm-med);
            color: $cobalt;
            background-color: $white;
            line-height: 20px;
            text-align: center;
            flex-grow: 1;
            max-width: 46px;
            border: 2px solid $cobalt;
            border-radius: 5px;
            cursor: pointer;
            &.active {
              background-color: $cobalt;
              color: $white;
            }
          }
        }
      }
    }
    .top-builderbox {
      width: 100%;
      padding: 4px 8px;
      min-width: 750px;
      align-items: baseline;
      vertical-align: top;
      background-color: $lightCloud;
      display: table-cell;
      .ant-steps-horizontal {
        user-select: none;
        width: 100% !important;
        margin-bottom: 4px;
        .ant-steps-icon {
          display: block;
          transform: translateY(3px) !important;
          font-weight: bold;
        }
        .ant-steps-item {
          .ant-steps-item-icon {
            border-color: $novaBlue;
            border-width: 2px;
            vertical-align: middle !important;
            @include font(sm-bold);
            .ant-steps-icon {
              color: $novaBlue;
            }
            i {
              color: $novaBlue;
            }
          }
          &-active {
            &::before {
              background-color: $novaBlue;
            }
            .ant-steps-item-icon {
              .ant-steps-icon {
                color: $white;
              }
              background-color: $novaBlue;
              border-color: $novaBlue;
              @include font(sm-bold);
            }
          }
          &-content {
            vertical-align: middle !important;
          }
          &-wait {
            .ant-steps-item-icon {
              border-color: $ash;
              .ant-steps-icon {
                color: $ash;
              }
            }
            .ant-steps-item-title {
              color: $ash;
            }
          }
        }
        .ant-steps-item-title {
          @include font(sm-med);
          font-size: 16px;
        }
      }
    }
    .bottom-builderbox {
      .tm-input {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &__label {
          margin-right: 8px;
        }
        .ant-input {
          background-color: $white !important;
        }
      }
    }
    .chainbuilder-navigation {
      display: flex;
      justify-content: space-between;
    }
    // .rightbuilderbox {
    //   padding: 17px 25px;
    //   width: 30%;
    //   display: inline-block;
    //   vertical-align: top;
    //   background-color: $lightCloud;
    // }
  }

  .availablesection {
    h2 {
      @include font(sm-bold);
      color: $cobalt;
      margin-bottom: 0;
    }
    .availablelinks-grid-container {
      margin-top: 8px;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.chainbuilder-row-menu {
  .ant-dropdown-menu-item {
    @include font(sm-med);
    color: $ash;
    padding: 0 21px;
    i {
      margin-right: 8px;
    }
    .remove-chain-row {
      color: $negative;
    }
  }
}
