@import 'src/shared/styles/colors.scss';

.modal-content {
  max-height: 90vh;
  overflow: hidden;
}

.modal-inner {
  padding: 0 24px;
  position: relative;
  overflow-y: auto;
  height: calc(100% - 74px);
}

.modal-header {
  border-bottom: 1.5px solid $cloud;
  display: flex;
  position: relative;
  padding: 16px 24px;
  &__title {
    @include font(h3);
    color: $novaBlue;
    margin: 0;
  }
  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.modal-section {
  &__title {
    @include font(h4);
    font-weight: 900;
    margin: 16px 0 0.5em;
  }
  &__divider {
    width: calc(100% + 48px);
    height: 1.5px;
    background-color: $cloud;
    margin: 0 -24px;
    &:last-of-type {
      display: none;
    }
  }
}

.modal-footer {
  // border-top: 1.5px solid $cloud;
  display: flex;
  &--right {
    flex-direction: row;
    justify-content: flex-end;
    & > button {
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  padding: 24px 0;
}
