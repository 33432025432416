@import '../../styles/colors.scss';
.action-menu-dropdown {
  color: $novaBlue !important;
  font-family: 'Eurostile' !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  display: flex !important;
  user-select: none;
  .icon-component {
    margin-right: 3px !important;
    &:hover {
      cursor: pointer;
    }
  }
}
.action-menu-item {
  padding: 5px 44px 5px 20px !important;
  transition: none !important;
  &:focus {
    background-color: #006bb6 !important;
    box-shadow: none !important;
    div,
    p,
    span {
      color: white !important;
    }
    svg,
    path {
      fill: white !important;
    }
  }
  &.ant-dropdown-menu-item-active {
    background-color: $novaBlue !important;
    .action-menu-item__link {
      color: $white !important;
    }
  }
  &__link {
    display: flex !important;
    font-family: 'Eurostile' !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    color: $ash !important;
    &.warning {
      color: $negative !important;
    }
  }
  &__icon {
    width: 20px !important;
    margin-right: 10px !important;
  }
}

.action-menu-label + .icon-component {
  margin-left: 6px;
}
