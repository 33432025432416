@import '../../../shared/styles/colors.scss';

.conveyor-card {
  width: 100%;
  min-width: 1200px;
  background-color: $white;
  margin: 0;
  border-radius: 4px;
  box-shadow: 0 1px 12px 0 rgba(41, 51, 67, 0.04);
  &.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    margin: 0;
    overflow: auto;
  }
  &__header {
    padding: 5px 10px;
    &__firstrow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__title {
        display: inline-block;
        @include font(h5);
        font-weight: 700;
        letter-spacing: 0.25px;
        line-height: 22px;
        margin: 0 15px 0 5px;
        padding-bottom: 7px;
        transform: translateY(4px);
        position: relative;
        max-width: 230px;
        vertical-align: top;
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          margin-left: 5px;
          cursor: pointer;
        }
        &.disabled span {
          cursor: default;
        }
      }
      &__tabs {
        display: flex;
        .tab {
          margin: 0 5px;
          &.completed {
            svg {
              margin-left: 3px;
            }
            &.tm-button__secondary {
              svg {
                path {
                  fill: $novaBlue;
                }
              }
            }
          }
        }
      }
      &__conveyor-cost {
        @include font(sm-bold);
        width: 120px;
        margin-left: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: right;
        margin-right: 16px;
      }
      &__action-menu {
        margin-right: 20px;
      }
      &__tm-status {
        @include font(sm);
        font-size: 14px;
        width: 150px;
        svg {
          margin-right: 2px;
        }
      }
      &__corespecmaterials {
        margin-left: 12px;
      }
    }
    &__secondrow {
      display: flex;
      align-items: center;
      margin: 5px 0 0 25px;
      &__units {
      }

      &__tm-status {
        @include font(sm);
        margin-left: 12px;
        svg {
          margin-right: 2px;
        }
      }
    }
  }
  &__bottom {
    background-color: $lightCloud;
    &__firstrow__tabs {
      padding: 2px 10px 0 10px;
      border-top: 1px solid rgba($silver, 0.4);
      display: flex;
      align-items: center;
      .custom-steps {
        display: flex;
        width: 280px;
        .step {
          display: flex;
          align-items: center;
          @include font(sm-med);
          color: $navy;
          // color: $darkAsh;
          font-size: 14px;
          padding: 2px 8px;
          width: auto;
          cursor: pointer;
          .circle {
            height: 16px;
            width: 16px;
            border: 1.5px solid $novaBlue;
            border-radius: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            .number {
              @include font(tiny);
              font-weight: 700;
              color: $novaBlue;
              font-size: 8px;
            }
          }
          &.completed {
            color: $navy;
            .circle {
              border: 1.5px solid $novaBlue;
            }
          }
          &.current {
            color: $navy;
            border-bottom: 5px solid $novaBlue;
            padding-bottom: 0;
            font-weight: 900;
            .circle {
              border: 1.5px solid $novaBlue;
              background-color: $novaBlue;
              .number {
                color: $white;
              }
            }
          }
          &.disabled {
            color: $ash;
            cursor: not-allowed;
            .circle {
              border: 1.5px solid $ash;
              .number {
                color: $ash;
              }
            }
          }
        }
      }
      .fullscreen-button {
        cursor: pointer;
        display: flex;
        @include font(sm-med);
        line-height: 24px;
        border: 1px solid $ash;
        border-radius: 2px;
        font-size: 14px;
        width: fit-content;
        padding: 0 12px;
        background-color: $white;
        margin-left: 166.75px;
        .icon-component {
          margin-right: 4px;
        }
      }
      .grabzone {
        margin: 0 auto;
      }
      .conveyor-card--open & {
        border-bottom: 1px solid rgba($silver, 0.4);
        // If we want to hide the grabzone when open here ⬇️ is how
        // .grabzone {
        //   display: none;
        // }
        // .fullscreen-button {
        //   margin-left: auto;
        // }
      }
    }
  }
  &__content {
    padding: 10px;
  }
  &--open {
  }
}
