@import '../../../../../../shared/styles/colors.scss';
.tm-results-table-container {
  .torquemaster-results {
    margin-top: 4px;
    h1 {
      @include font(h4);
      letter-spacing: -0.58px;
      font-weight: bold;
      margin:0px;
    }
    .torquemaster-results-summary {
      @include font(sm-med);
      margin-top: 4px;
      position: relative;
      span {
        @include font(sm);
        position: absolute;
        left: 180px;
        color: $ash;
      }
    }
  }
  .normal-transport-calculations {
    margin-top: 4px;
    .ant-table-body {
      @include font(sm);
      color: $ash;
      .ant-table-thead {
        th {
          @include font(sm-med);
          background-color: $cloud;
          &.ant-table-row-cell-break-word {
            padding: 0px;
          }
          &.two-column-span {
            padding: 0px;
            border-bottom: 3px solid $lightCloud;
          }
          &.force {
            padding: 0px;
            padding-left: 29px !important;
          }
          &.temp {
            padding: 0px;
            padding-left: 20px !important;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-row-cell-break-word {
            padding: 0px;
        }
          .force {
            padding: 0px;
        } 
          .temp {
              padding: 0px;
          }
        }
      }
    }
  }
  .product-accum-calculations {
    margin-top: 4px;
    .ant-table-body {
      @include font(sm);
      color: $ash;
      .ant-table-thead {
        th {
          @include font(sm-med);
          background-color: $cloud;
          &.ant-table-row-cell-break-word {
            padding: 0px;
          }
          &.two-column-span {
            padding: 0px;
            border-bottom: 3px solid $lightCloud;
          }
          &.force {
            padding: 0px;
            padding-left: 29px !important;
          }
          &.temp {
            padding: 0px;
            padding-left: 20px !important;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          .ant-table-row-cell-break-word {
            padding: 0px;
        }
          .force {
            padding: 0px;
        } 
          .temp {
              padding: 0px;
          }
        }
      }
    }
  }
  h2 {
    @include font(h5);
    color: $novaBlue;
    font-weight: bold;
    margin:0px;
  }
}
