@import 'src/shared/styles/colors.scss';

.navbar__breadcrumbs {
  margin-left: 30px !important;
  .ant-breadcrumb-link {
    @include font(sm);
    color: $darkAsh;
    cursor: pointer;

    a {
      transition: none;
      &:focus-visible {
        border-radius: 2px;
        box-shadow: 0 0 0 2px $novaBlue;
      }
      &:hover {
        color: $darkAsh;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        color: $novaBlue;
        .icon-component {
          path,
          svg {
            fill: $novaBlue;
          }
        }
      }
    }

    .icon-component {
      display: inline-block;
      margin-right: 3px;
      vertical-align: text-bottom;
      .svg__img {
        vertical-align: inherit !important;
        margin-bottom: -1px !important;
      }
    }
  }
}

.breadcrumb--last {
  color: $novaBlue;
  font-weight: bold;
  cursor: default;
}
