@import '../../../../shared/styles/colors.scss';
.custom-date-picker {
  position: relative;
  &__select-dropdown {
    height: 32px;
    width: 200px;
    border: 1px solid $ash;
    border-radius: 4px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__text {
      @include font(b);
      font-size: 16px;
    }
  }
  &__contents {
    padding: 10px 0;
    border-radius: 4px;
    box-shadow: 0 1px 8px 0.1px rgba(23, 50, 97, 0.215);
    position: absolute;
    height: 266px;
    width: 342px;
    top: 34px;
    left: 0;
    background-color: $white;
    z-index: 4;
    .preset {
      @include font(sm);
      color: $ash;
      padding: 0 20px;
      &:hover {
        background-color: $novaBlue;
        color: $white;
      }
    }
    .picker {
      padding: 0 20px 6px 20px;
      @include font(sm-med);
      &:hover {
        background-color: $novaBlue;
        color: $white;
      }
      .month-pickers {
        display: flex;
        justify-content: space-between;
      }
      .ant-calendar-picker-input {
        background-color: $white !important;
        border: 1px solid $ash !important;
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
}
