@import '../../shared/styles/colors.scss';
#modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  &__content {
    background: $white;
    border-radius: 4px;
    box-shadow: 0 1px 3.96px 0.04px rgba(71, 40, 155, 0.1);
    max-height: 90%;
    overflow-y: auto;
  }
}
