@import '../../styles/colors.scss';
.tm-input {
  position: relative;
  .ant-input-affix-wrapper {
    // style applied when allowClear is true
    &.small {
      height: 32px;
    }
    &.medium {
      height: 40px;
    }
    &.large {
      height: 50px;
    }
  }
  .ant-input {
    background-color: $lightCloud !important;
    @include font(sm);
    border: none;
    color: $deepNavy;
    padding-right: 5px;
    &:focus {
      box-shadow: none;
    }
    &.small {
      height: 32px;
    }
    &.medium {
      height: 40px;
    }
    &.large {
      height: 50px;
    }
    &.disabled {
      background-color: $lightCloud;
    }
    &.has-error {
      border: 1px solid $negative !important;
    }
  }
  &__label {
    @include font(sm-med);
    color: $novaBlue;
  }
  .icon-component {
    width: 26px;
    position: absolute;
  }
  .ant-input-group-wrapper {
    .ant-input-group.ant-input-wrapper {
      height: inherit;
      .ant-input {
        height: inherit;
        margin: 0;
      }
      .ant-input-group-wrapper {
        top: -3px;
      }
      .ant-input-group-addon {
        padding: 0 8px;
        // border-right: none !important;
        // box-shadow: inset 0px 0px 0px 1px rgba(145,150,161,0.25);
        .has-error & {
          border-color: $negative;
        }
      }
    }
    &.small {
      height: 32px;
    }
    &.medium {
      height: 28px;
    }
    &.large {
      height: 50px;
    }
  }
}

form.ant-form {
  .ant-row {
    .ant-form-item-label {
      label {
        @include font(sm-med);
        color: $novaBlue;
        &::after {
          content: '';
        }
        line-height:25px;
      }
    }
    .ant-form-item-control-wrapper {
      .ant-form-item-control {
        &.has-error {
          .ant-form-item-children {
            input {
              border-color: $negative !important;
            }
          }
        }
        .ant-form-explain {
          @include font(tiny);
          position: absolute;
          padding: 4px 0 0 4px;
          color: $negative;
        }
      }
    }
  }
}
