@import '../../styles/colors.scss';
.tm-radio {
  &__label {
    @include font(sm-bold);
    color: $novaBlue;
    line-height: 26px;
  }
  &__image {
    border: 3px solid transparent;
    &.tiny {
      display: inline-block;
      margin-left: 0;
      margin-right: 7px;
      border: none;
      img {
        width: 15px;
        height: 15px;
      }
      &.selected {
        border: none;
        &.disabled {
          border-color: $darkAsh;
        }
      }
    }
    &.selected {
      border: 3px solid $novaBlue;
      &.disabled {
        border-color: $darkAsh;
      }
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    &.vertical {
      flex-direction: column;
    }
    .tm-radio-option-wrapper {
      display: flex;
      line-height: 28px;
      span {
        @include font(sm);
        line-height: 24px;
      }
      &.disabled {
        .ant-radio-wrapper {
          cursor: not-allowed;
          .ant-radio-inner {
            background-color: $silver;
            border-color: $darkAsh !important;
            &::after {
              background-color: $darkAsh !important;
            }
          }
        }
      }
      .ant-radio-wrapper {
        @include font(sm);
        line-height: 24px;
        .title {
          display: inline-block;
          transform: translateY(2px);
        }
        span.ant-radio {
          .ant-radio-inner {
            border-color: $darkAsh;
            border-width: 2px;
          }
          &.ant-radio-checked {
            .ant-radio-inner {
              border-color: $novaBlue;
              border-width: 2px;
              &::after {
                top: 2px;
                left: 2px;
                background-color: $novaBlue;
              }
            }
          }
          & + span {
            .title + .tm-radio__image > img {
              margin-bottom: 7px;
            }
          }
        }
      }
    }
  }
  .ant-form-item-control {
    .ant-form-explain {
      bottom: -3px;
      left: 15px;
    }
  }
  &--haslrgimage {
    .ant-radio + span {
      display: inline-flex;
      flex-direction: column;
    }
  }
}
