@import '../../../../shared/styles/colors.scss';

.project-status-history {
  width: 900px;
  padding: 24px;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    h1 {
      @include font(h3);
      color: $novaBlue;
    }
  }
  &__bottom {
    h1 {
      @include font(b-bold);
      margin-bottom: 0;
    }
    p {
      @include font(b);
      margin-right: 14px;
      .icon-component {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
  .ant-timeline {
    max-height: 45vh;
    overflow: auto;
    padding: 20px;
    border-top: 1px solid $silver;
    .ant-timeline-item {
      padding: 0;
      margin-bottom: 11px;
      .ant-timeline-item-content {
        top: -9px;
        p {
          @include font(b);
          font-size: 12px;
          line-height: 18px;
          margin-bottom: 3px;
          &:first-child {
            @include font(sm);
          }
          &:last-child {
            padding-left: 10px;
          }
        }
      }
    }
  }
}
