@import '../../shared/styles/colors.scss';

.estimator-container {
  .estimator-subtitle {
    @include font(h5);
    font-weight: 600;
    margin-bottom: 0;
  }
  .ant-steps-horizontal {
    @include font(sm);
    width: 800px;
    .ant-steps-item {
      .ant-steps-item-container {
        .ant-steps-item-title {
          @include font(sm-med);
          cursor: pointer;
          &::after {
            background-color: $novaBlue;
          }
        }
        .ant-steps-item-icon {
          border-width: 2px;
          width: 24px;
          height: 24px;
          margin-top: 2px;
          .ant-steps-icon {
            top: -7px;
            font-size: 15px;
            font-weight: bold;
          }
        }
      }
      &-active {
        .ant-steps-item-icon {
          font-family: 'Eurostile';
          background-color: $novaBlue;
          border-color: $novaBlue;
          color: $novaBlue;
        }
      }
      &-finish {
        .ant-steps-item-icon {
          font-family: 'Eurostile';
          border-color: $novaBlue;
          .ant-steps-icon {
            color: $novaBlue;
            font-size: 12px !important;
            font-weight: bold;
          }
        }
      }
      &-wait {
        .ant-steps-item-icon {
          font-family: 'Eurostile';
          border-color: $darkAsh;
          .ant-steps-icon {
            color: $darkAsh;
          }
        }
        .ant-steps-item-container {
          .ant-steps-item-title {
            color: $darkAsh;
          }
        }
      }
    }
  }
}

.estimator-steps {
  width: 100% !important;
  &__step {
    flex: 0 1 auto;
    padding-right: 48px;
    &:last-child {
      padding-right: 0;
    }
    .ant-steps-item-title::after {
      width: 48px;
    }
  }
}
