@import '../../../../../../shared/styles/colors.scss';

.builder-list {
  width: 530px;
  background-color: $cloud;
  border: 1px solid $cloud;
  padding: 3px 3px 3px 3px;
  overflow: auto;
  margin-top:-8px;
  div {
    &:focus {
      outline: none;
    }
  }
}

.add-a-section {
  background-color: $cloud;
  @include font(h5);
  font-weight: 600;
  text-align: left;
  padding:8px;
  .text {
    margin-bottom:-4px;
  }
  .section-type-buttons {
    display: flex;
    align-items: center;
    &__button {
      @include font(sm-med);
      margin-left:5px;
      position: relative;
      color: $darkAsh;
      width: 180px;
      border: 2px solid $darkAsh;
      background-color: $lightCloud;
      text-align: center;
      border-radius: 4px;
      &.disabled {
        border-color: $silver;
        color: $silver;
        &:hover {
          cursor: not-allowed;
          background-color: $lightCloud;
          border-color: $silver;
        }
      }
      &:hover,
      &:focus {
        background-color: $white;
        border-color: $novaBlue;
      }
      &:focus-visible {
        box-shadow: 0 0 0 1px $novaBlue;
      }
      .icon-component {
        display: inline-block;
        position: absolute;
        left: 8px;
        top: -1px;
      }
    }
    .tm-button {
      margin: 3px 8px;
    }
  }
}
