@import '../../../../../../shared/styles/colors.scss';

.validate-options {
  padding: 10px 0;
  table {
    border: none !important;
    td {
      border: none !important;
      border-bottom: 1px solid $silver !important;
    }
    th {
      border-bottom: 1px solid $silver !important;
    }
  }
  .ant-table-body {
    @include font(sm);
    .ant-table-thead {
      th {
        border: none;
        @include font(sm-bold);
        text-align: center;
        background-color: $lightCloud;
        padding: 10px 10px;
        &:first-of-type {
          text-align: left;
        }
      }
    }
    .ant-table-tbody {
      position: relative;
      .ant-table-row {
        td {
          padding: 8px 10px;
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .ant-form-item {
            text-align: center;
            .ant-form-explain {
              width: max-content !important;
              padding: 3px;
            }
          }
          .tagged-conveyor-popover {
            margin-left: 8px;
            background-color: $novaBlue;
            padding: 1px 7px 3px 7px;
            border-radius: 3px;
            span {
              @include font(sm-med);
              color: $white;
              font-size: 14px;
              font-weight: 700;
              line-height: 28px;
            }
          }
          .validation-table-actions {
            position: relative;
            .select-conveyor {
              width: 290px;
              position: absolute;
              right: -17px;
              top: 27px;
              z-index: 1;
            }
            .select-conveyor.ant-select.ant-select-enabled {
              .ant-select-dropdown-menu-item {
                font-weight: 700;
              }
              .ant-select-selection__rendered {
                margin-right: 0;
                .ant-select-selection__choice {
                  width: 100%;
                  border: 1px solid $navy;
                  .ant-select-selection__choice__content {
                    @include font(sm);
                    font-weight: 700;
                    line-height: 20px;
                    color: $navy;
                  }
                  .ant-select-selection__choice__remove {
                    .anticon.anticon-close.ant-select-remove-icon {
                      color: $navy;
                      font-size: 15px;
                      position: relative;
                      top: 1px;
                      right: 0;
                    }
                  }
                }
              }
            }
            .select-conveyor-options {
              @include font(sm-med);
              padding-left: 18px;
              &.ant-select-dropdown-menu-item-active {
                background-color: $white;
                &:hover {
                  background-color: $novaBlue;
                  color: $white;
                  svg {
                    fill: $white;
                  }
                }
              }
            }
          }
        }
        &:hover {
          background-color: $novaBlue !important;
          td {
            background-color: $novaBlue !important;
            color: $white;
            &.ant-table-row-expand-icon-cell {
              color: $navy;
              font-weight: normal;
            }
          }
        }
      }
    }
    .muf,
    .cost,
    .quantity,
    .discount {
      .ant-input {
        text-align: right;
      }
    }
  }
  .ant-table-footer {
    padding: 11px;
    border: none !important;
    border-radius: 0;
    border-top: 1px solid $silver !important;
    border-bottom: 1px solid $silver !important;
    .validate-options__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
    }
  }
  &__add-option-select {
    margin: 20px 0;
    .ant-select {
      @include font(sm);
      border: none;
      color: $deepNavy;
      width: 271px;
      .ant-select-selection-selected-value {
        color: rgba(0, 0, 0, 0.25);
      }
      .ant-select-selection {
        background-color: $cloud;
        border-color: $silver;
        box-shadow: none;
        &-selected-value {
          color: $deepNavy;
        }
      }
    }
  }
}
.options-table-select__tree {
  @include font(sm);
  line-height: 20px;
  .ant-select-tree-node-content-wrapper {
    cursor: default !important;
    &:hover {
      color: $darkAsh;
      background-color: $white !important;
    }
  }
  .ant-select-tree-child-tree {
    .ant-select-tree-node-content-wrapper {
      &:hover {
        color: $white;
        background-color: $novaBlue !important;
        cursor: pointer !important;
      }
    }
  }
}
