@import '../../shared/styles/colors.scss';
.projects-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .projects-title {
    @include font(h2);
    color: $novaBlue;
    width: 1100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;
  }
  .project-tabs {
    width: 100%;
    .ant-tabs-bar {
      margin: 0;
      .ant-tabs-nav-container {
        display: flex;
        justify-content: center;
        height: 48px !important;
        background-color: $mediumCloud;
        border-top: 1px solid $lightSilver;
        border-bottom: 1px solid $lightSilver;
        .ant-tabs-ink-bar {
          z-index: 0;
          height: 5px;
          background-color: $novaBlue;
        }
        .ant-tabs-nav-wrap {
          width: 1100px;
        }
      }
      .ant-tabs-tab {
        border: none;
        height: 48px;
        @include font(b-bold);
        font-size: 16px;
        color: $ash !important;
        border-radius: 4px 4px 0 0;
        line-height: 1.73;
        padding: 10px 25px;
        margin-right: 2px;
        background-color: $mediumCloud;
        &.ant-tabs-tab-active {
          border: none;
          color: $navy !important;
        }
        .queue-tab-text {
          display: flex;
          align-items: center;
          .queue-tab-number {
            color: $white;
            font-size: 8px;
            font-weight: normal;
            background-color: $novaBlue;
            width: 16px;
            height: 16px;
            border-radius: 16px;
            margin-left: 4px;
            line-height: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    &__tab {
      &--queue #project-list {
        height: calc(100vh - 360px);
      }
      &--search #project-list {
        height: calc(100vh - 360px);
      }
    }
  }
}
