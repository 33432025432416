@import '../../../../shared/styles/colors.scss';
.project-tab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .input-bar {
    z-index: 0 !important;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 2;
    padding: 24px 0;
    box-shadow: 0 1px 3.96px 0.04px rgba(71, 40, 155, 0.1);
    &__inputs {
      width: 1100px;
      display: flex;
      justify-content: space-between;
    }
  }
  .ant-input {
    border: 1px solid rgba(192, 197, 207, 0.4);
  }
  .ant-pagination {
    margin: 16px;
    &-item {
      a:hover {
        color: $novaBlue;
      }
      &:hover,
      &-active {
        border-color: $novaBlue;
        a {
          color: $novaBlue;
        }
      }
    }
  }
}
