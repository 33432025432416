@import '../../../../../shared/styles/colors.scss';

.cost-import__table {
  width: 100%;

  .ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
    color: $novaBlue;
  }

  .ant-empty-description {
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--editing {
    .ant-table-body {
      margin-bottom: -1px;
    }
    .ant-table-footer {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      margin: 0 auto;
      z-index: 2;
      &::before {
        background: #e8e8e8;
      }
    }
    th,
    td {
      &:nth-child(6),
      &:nth-child(8),
      &:nth-child(10),
      &:nth-child(12),
      &:nth-child(14) {
        border-left: 1px solid $mediumSilver;
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
  }

  .ant-table-scroll {
    overflow: visible !important;
  }
}

.cost-import__input {
  display: inline-block;
  margin-left: auto;
  width: auto !important;
}

.table-filter-box {
  padding: 16px 12px;
  width: 263px;
  &--130 {
    width: 130px;
  }
  &__search {
    margin-bottom: 16px;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
  }
  &__label {
    font-family: 'Eurostile';
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
    color: $novaBlue;
    display: block;
  }
}

.cost-import-icon {
  font-size: 20px;
  cursor: pointer;
  color: $ash;
  &--lock {
    color: $novaBlue;
  }
  &:hover:not(.cost-import-icon--disabled) {
    color: $deepNavy !important;
  }
  &--disabled {
    cursor: not-allowed !important;
    color: $ash;
    &.cost-import-icon--lock {
      color: desaturate($novaBlue, 40%);
    }
  }
}

.cost-import-desc-tooltip {
  .ant-tooltip-inner {
    background-color: white !important;
    color: $navy;
    @include font(e-14);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4) !important;
  }
  .ant-tooltip-arrow::before {
    background-color: white !important;
  }
}

.ant-switch-checked {
  background-color: $novaBlue;
}
