@import 'shared/styles/colors';

.error-boundary {
  width: 500px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 56px 0;

  &__heading {
    @include font(h1);
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-icon {
      font-size: 56px;
      margin-bottom: 16px;
    }
  }

  &__desc {
    @include font(h5);
    margin-bottom: 4px;
    text-align: center;
    &:last-of-type {
      margin-bottom: 24px;
    }
  }

  &__actions {
    margin-bottom: 56px;
    display: flex;
    justify-content: center;

    .tm-button:nth-child(n + 2) {
      margin-left: 16px;
    }
  }

  &__log {
    display: none;
  }
}
