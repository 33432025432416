@import '../../../../shared/styles/colors.scss';

.add-ext-user {
  &__title {
    @include font(h3);
    color: $novaBlue;
    border-bottom: 1.5px solid $cloud;
    padding: 16px 24px;
    margin: 0;
    p.error {
      @include font(sm);
      color: $negative;
      font-weight: normal;
      margin: 0;
    }
  }
  &__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .ant-form-item {
      margin-bottom: 16px;
    }
    .ant-form-item-label {
      line-height: 28px;
      margin-bottom: 4px;
    }
  }
  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  &__button {
    margin: 0 0 0 20px;
  }
  &__section-heading {
    @include font(h4);
    font-weight: bold;
    font-size: 25px;
    padding-top: 16px;
  }
}
.ant-form {
  padding: 0;
}
.ant-row-flex {
  position: relative;
  width: calc(100% + 16px);
}

.modal-divider {
  border-bottom: 1.5px solid $cloud;
  width: 100%;
  height: 1.5px;
}

.u-mb-4 {
  margin-bottom: 4px;
}
