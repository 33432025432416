@import '../../../../shared/styles/colors.scss';
#add-user {
  width: 600px;
  height: auto;
  padding-block: 1.4rem;
  position: relative;
  overflow: auto;
  position: relative;
  .add-user {
    &__title {
      @include font(h3);
      color: $novaBlue;
      border-bottom: 1.5px solid $cloud;
      padding: 20px;
      margin: 0;
      p.error {
        @include font(sm);
        color: $negative;
        font-weight: normal;
        margin: 0;
      }
    }
    &__form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: calc(100% - 16px);
      .ant-row-relative {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .ant-form-item {
        margin-right: 20px;
        margin-bottom: 10px;

        #add-user_roles {
          border: none;
          height: 100%;
        }
      }
    }
    &__close {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    &__button {
      margin: 0 0 0 20px;
    }
  }
  .ant-form {
    padding: 20px 0 20px 20px;
  }
}
