@import '../../../../../../shared/styles/colors.scss';

.validate-conveyors {
  padding: 4px 0;
  overflow-y: auto;
  table {
    border: none !important;
    td {
      border: none !important;
      border-bottom: 1px solid $silver !important;
      padding:0px;
    }
    th {
      border-bottom: 1px solid $silver !important;
    }
  }
  .ant-table-body {
    @include font(sm);
    .ant-table-thead {
      th {
        border: none;
        @include font(sm-bold);
        text-align: right;
        background-color: $lightCloud;
        padding: 3px 10px;
        &:first-of-type {
          text-align: left;
        }
      }
    }
    .ant-table-tbody {
      position: relative;
      .ant-table-row {
        height: 28px;
        td {
          padding: 3px 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 210px;
          text-align: right;
          &:first-of-type {
            text-align: left;
          }
          .ant-form-item {
            text-align: center;
            .ant-form-explain {
              width: max-content !important;
              padding: 3px;
            }
          }
        }
        &:hover {
          background-color: $novaBlue !important;
          td {
            background-color: $novaBlue !important;
            color: $white;
            &.ant-table-row-expand-icon-cell {
              color: $navy;
              font-weight: normal;
            }
            .minus {
              rect {
                fill: $ash !important;
              }
            }
          }
        }
      }
    }
    .muf,
    .cost,
    .quantity {
      .ant-input {
        text-align: right;
      }
    }
    .variable-muf {
      &__input {
        @include font(sm);
        font-size: 16px;
        padding: 0 5px !important;
        background-color: $white !important;
        width: 60px;
        border: 1px solid $silver;
        &.ant-input-disabled {
          background-color: $mediumCloud !important;
          border: none !important;
        }
      }
      &__master {
        display: flex;
        align-items: center;
        &__text {
          width: 50px;
        }
      }
    }
    .discount,
    .discountpercentage {
      .ant-input-wrapper.ant-input-group {
        padding-top: 4px !important;
        .ant-input-disabled {
          color: $navy !important;
          background-color: $mediumCloud !important;
          border: none !important;
        }
      }
    }
    .variable-discount__input.disabled.ant-input-group-wrapper {
      .ant-input-group-addon {
        border: none !important;
      }
    }
  }
  .ant-table-footer {
    padding: 11px;
    border: none !important;
    border-radius: 0;
    border-top: 1px solid $silver !important;
    border-bottom: 1px solid $silver !important;
  }
  .expanded-conveyor-options {
    tr {
      height: 28px;
    }
  }
}

.table-row-powertrain td:first-child {
  overflow: visible !important;
  position: relative;
}
// .ant-select {
//   z-index: 1;
//   margin-left: 20px;
//   .ant-select-selection {
//     background-color: $cloud;
//     .ant-select-selection__placeholder {
//       color: $navy;
//     }
//   }
// }
// .ant-select-tree-title {
//   color:#9196A1;
//   font-family: Eurostile;
// }
// .options-table-select {
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   width: 100%;
//   height: 55px;

//   &__tree {
//     li.ant-select-tree-treenode-switcher-close {
//       margin: 0;
//       .ant-select-tree-node-content-wrapper {
//         &:hover {
//           background-color: $novaBlue;
//           :first-child {
//             color: white;
//           }
//         }
//       }
//     }
//     .ant-select-dropdown-search {
//       .ant-select-search__field__wrap {
//         .ant-select-search__field__placeholder {
//           font-family: Eurostile;
//           justify-content: space-between;
//           align-items: center;
//           .tree-search-icon {
//             position: absolute;
//             top: 0;
//             right: 0;
//           }
//         }
//       }
//     }
//   }
//   &.tm-button--small{
//     height: 32px;
//     padding: 0;
//     margin-left: 20px;
//     p {
//       font-size: 16px;
//     }
//   }
// }
// .delete-icon {
//   color: #DD1C1A;
// }

// input#cost.ant-input {
//   width: 70px;
// }

.expand-row-icon {
  &:focus-visible .icon-component {
    border-radius: 2px;
    box-shadow: 0 0 0 2px $novaBlue;
  }
}
