@import '../../../../shared/styles/colors.scss';
#users-by-role {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .users-by-role {
    &__section {
      @include font(sm);
      height: 620px;
      width: 580px;
      padding: 20px;
      border-radius: 4px;
      background-color: $white;
      box-shadow: 0 1px 8px 0.1px rgba(23, 50, 97, 0.1);
      margin-bottom: 32px;
      margin-right: 32px;
      &__top {
        display: flex;
        justify-content: space-between;
        &__left {
          .title {
            @include font(b);
          }
          .total-validated {
            @include font(sm);
            display: flex;
            flex-direction: column;
            &-price {
              display: flex;
              align-items: flex-end;
              &__dollars {
                @include font(h1);
              }
              &__statistics {
                @include font(sm);
                margin-left: 20px;
              }
            }
          }
        }
        &__right {
          .badge {
            width: 72px;
            height: 72px;
            border-radius: 72px;
            background-color: $novaBlue;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      &__bottom {
        margin-top: 20px;
        &__table {
          table {
            width: 100%;
            thead {
              th {
                color: $navy !important;
                font-weight: bold;
                padding: 13px 16px;
              }
            }
            tbody {
              @include font(sm);
              td {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .ant-table-row {
                &:hover,
                :active,
                :focus {
                  background-color: $novaBlue !important;
                  td {
                    background-color: $novaBlue !important;
                    color: $white;
                    &.ant-table-row-expand-icon-cell {
                      color: $navy;
                      font-weight: normal;
                    }
                    svg {
                      color: $white !important;
                    }
                  }
                }
              }
              .icon-component {
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
          .ant-pagination {
            display: flex;
            width: 100%;
            @include font(sm);
            &-total-text {
              margin-right: auto;
            }
            &-item {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid $ash;
              border-radius: 6px;
              a {
                @include font(sm);
              }
              &-active {
                background-color: $novaBlue;
                border: none;
                a {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}
