@import '~antd/dist/antd.css';
@import './colors.scss';

#root {
  width: 100%;
  margin: 0;
  user-select: none;
  .app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .authenticated-screen {
      display: flex;
      justify-content: center;
    }
  }
}

.ant-popover-inner-content {
  font-family: 'Museo-sans';
  .ant-popover-message {
    font-size: 15px;
    font-weight: bold;
    color: black;
    i {
      display: none;
    }
    .ant-popover-message-title {
      padding: 0;
    }
  }
  .ant-popover-buttons {
    button {
      height: 30px;
      width: 80px;
      font-weight: bold;
      font-size: 13px;
    }
    .ant-btn.ant-btn-sm {
      color: $darkAsh;
      border: 2px solid $ash;
      background-color: $white;
      &:hover {
        color: $white;
        border: 2px solid $ash;
        background-color: $ash;
      }
      &.ant-btn-primary {
        color: $negative;
        background-color: $white;
        border: 2px solid $negative;
        &:hover {
          background-color: $negative;
          border: 1px solid $negative;
          color: $white;
        }
      }
    }
  }
}

.ant-radio-group {
  .ant-radio-wrapper {
    color: $navy;
    @include font(sm);
    font-size: 14px;
    span.ant-radio {
      .ant-radio-inner {
        border-color: $darkAsh;
        border-width: 2px;
      }
      &.ant-radio-checked {
        .ant-radio-inner {
          border-color: $novaBlue;
          border-width: 2px;
          &::after {
            top: 2px;
            left: 2px;
            background-color: $novaBlue;
          }
        }
      }
    }
  }
}

.ant-input {
  background-color: $cloud !important;
  border: 1px solid $mediumSilver !important;
  @include font(sm);
  border: none;
  color: $deepNavy;
  padding-right: 5px;
  height: 28px;
  &:focus {
    box-shadow: 0 0 0 1px $novaBlue;
    border-color: $novaBlue !important;
  }
  &.small {
    height: 32px;
  }
  &.medium {
    height: 40px;
  }
  &.large {
    height: 50px;
  }
  &.disabled {
    background-color: $lightCloud;
  }
}

.ant-input-group-wrapper {
  &.left {
    .ant-input {
      border-left: none !important;
      padding: 3px 10px;
    }
  }
  &.right {
    .ant-input {
      border-right: none !important;
      padding: 3px 10px;
    }
  }
  span.ant-input-group-addon {
    border: 1px solid $mediumSilver;
    padding: 0 6px 0 5px;
    @include font(sm);
    line-height: 26px;
  }
}

.ant-select {
  @include font(sm);
  border: none;
  color: $deepNavy;
  &:hover,
  :active,
  :focus {
    outline: none;
    box-shadow: none;
    border-color: $silver;
  }
  &.small {
    height: 32px;
  }
  &.medium {
    height: 40px;
  }
  &.large {
    height: 50px;
  }
  &.disabled {
    .ant-select-selection {
      background-color: $lightCloud;

      &-selected-value {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .ant-select-selection {
    background-color: $cloud;
    height: 28px;
    &:hover,
    :active,
    :focus {
      outline: none;
      box-shadow: none;
      border-color: $silver;
    }
    &__clear {
      .ant-select-clear-icon {
        background-color: $cloud;
        vertical-align: 0;
      }
    }
    &__rendered {
      height: inherit;
      line-height: 26px;
    }
    &-selected-value {
      height: inherit;
      // display: flex !important;
      // align-items: center;
    }
  }
  &-dropdown {
    width: auto !important;
  }
}
.ant-form-item-label {
  line-height: 25px;
  label {
    @include font(sm-med);
    color: $novaBlue;
  }
}
.ant-form-item-control-wrapper {
  .ant-form-item-control {
    line-height: 28px;
    &.has-error {
      margin-bottom:14px;
      .ant-form-item-children {
        input {
          border: 1px solid $negative !important;
        }
      }
    }
    .ant-form-explain {
      @include font(tiny);
      position: absolute;
      padding: 4px 0 0 4px;
      color: $negative;
    }
  }
}
.radio-title {
  @include font(sm-bold);
  color: $novaBlue;
  font-weight: 600;
}

.ant-checkbox-wrapper {
  @include font(sm);
  .ant-checkbox {
    border-radius: 3px;
    .ant-checkbox-inner {
      border: 2px solid $ash;
    }
    &-checked {
      .ant-checkbox-inner {
        border: 1px solid $novaBlue;
        background-color: $novaBlue;
      }
    }
    &-disabled {
      .ant-checkbox-inner {
        background-color: $darkAsh;
        &::after {
          border-color: $silver;
        }
      }
    }
  }
}
.ant-select-dropdown--empty {
  .ant-select-dropdown-menu-item-disabled {
    background-color: $white !important;
  }
}
// If table has no data
.ant-table-placeholder {
  .ant-empty.ant-empty-normal {
    margin: 0;
    .ant-empty-image {
      display: none;
    }
  }
}

.ant-calendar-input {
  @include font(tiny);
  font-size: 14px !important;
}

.ant-tag-blue {
  @include font(sm);
  font-size: 14px;
  color: $white;
  background-color: $novaBlue;
  border: none;
}

.ant-calendar-picker-container {
  @include font(sm);
  .ant-calendar-selected-day {
    .ant-calendar-date {
      background-color: $novaBlue !important;
      color: $white !important;
    }
  }
  .ant-calendar-today {
    .ant-calendar-date {
      border-color: $novaBlue !important;
      color: $novaBlue !important;
      font-weight: 900 !important;
    }
    &.ant-calendar-selected-day {
      .ant-calendar-date {
        color: $white !important;
      }
    }
  }
}

.ant-message-notice-content {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.35);
  .ant-message-custom-content {
    &.ant-message-success {
      .anticon {
        color: $positive !important;
      }
    }
    &.ant-message-error {
      .anticon {
        color: $negative !important;
      }
    }
    span {
      @include font(b);
      font-size: 15px;
      font-weight: bold;
    }
  }
}

button {
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
}

.ant-radio:focus-within {
  .ant-radio-inner {
    box-shadow: 0 0 0 1px $deepNavy;
  }
}
