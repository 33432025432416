@import '../../../../../../shared/styles/colors.scss';
.builder-canvas {
  flex: 1;
  border: 1px solid navy;
  display: flex;
  justify-content: space-between;
  position: relative;
  .rotate-canvas-button {
    width: 135px;
    height: 29px;
    text-align: center;
    @include font(e14);
    font-weight: 700;
    position: absolute;
    color: $white;
    left: 140px;
    top: 11px;
    border: 3px solid $white;
    border-radius: 3px;
    padding: 2.5px 8px;
    &:hover {
      background-color: rgba(211, 211, 211, 0.651);
      cursor: pointer;
    }
  }
  #uncontrolled {
    background-color: $cloud;
    width: 100%;
    .viewer {
      #controls {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        position: absolute;
        background: -moz-linear-gradient(
          top,
          rgba(16, 23, 53, 1) 51%,
          rgba(255, 255, 255, 0) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          top,
          rgba(16, 23, 53, 1) 51%,
          rgba(255, 255, 255, 0) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to bottom,
          rgba(16, 23, 53, 1) 51%,
          rgba(255, 255, 255, 0) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#101735', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
        #zoom-bar {
          margin-left: auto;
          margin-right: 20px;
          width: 250px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 0;
          svg {
            color: white;
          }
          .jss1 {
            stroke: none;
            fill: white;
            font-size: 30px;
          }
          .zoom-in {
            stroke: none;
            fill: white;
            margin-left: 10px;
            margin-right: 5px;
            &:hover {
              cursor: pointer;
            }
          }
          .zoom-out {
            margin-right: 5px;
            &:hover {
              cursor: pointer;
            }
          }
          .jss12,
          .jss5 {
            background-color: #f7f6f6;
            opacity: 0.4;
          }
          .jss24 {
            background-color: #f7f6f6;
          }
        }
        .re-center {
          @include font(sm-med);
          line-height: normal;
          margin-left: 20px;
          text-align: center;
          width: 100px;
          height: 29px;
          color: white;
          border: 3px solid white;
          border-radius: 3px;
          font-family: 'Eurostile', sans-serif;
          font-size: 15px;

          &:hover {
            background-color: rgba(211, 211, 211, 0.651);
            cursor: pointer;
          }
        }
      }
      #main-canvas {
        width: 100%;
      }
    }
  }
  .add-remove {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
    padding: 0 20px;
    div {
      margin: 5px;
      padding: 10px;
      border: 1px dotted green;
      display: flex;
      flex-direction: column;
      .section-info {
        display: flex;
        flex-direction: row;
      }
      div {
        border: none;
        padding: 0;
        margin-bottom: 10px;
      }
      button {
        background-color: lightgrey;
        margin: 1px 0;
      }
    }
  }
}
#section-0 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
  font-size: 13px;
}
