@import '../../../../shared/styles/colors.scss';
#project-details {
  width: 650px;
  padding: 14px;
  position: relative;
  .project-details {
    &__header {
      @include font(h3);
      color: $novaBlue;
      margin-bottom:6px;
      p.error {
        @include font(sm);
        color: $negative;
        font-weight: normal;
        margin: 0;
      }
    }
    &__section {
      h2 {
        @include font(h4);
        font-weight: 900;
        margin:0px;
      }
      #customerid {
        &.ant-select-disabled {
          .ant-select-selection {
            background-color: $white;
            border: none;
            span.ant-select-arrow {
              display: none;
            }
          }
        }
      }
    }
  }
  .divider {
    width: 100%;
    height: 1.5px;
    background-color: $cloud;
    margin-bottom: 2px;
  }
  .icon-component {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
