@import '../../styles/colors.scss';
.tm-select {
  position: relative;
  .ant-select {
    @include font(sm);
    border: none;
    color: $deepNavy;
    &:hover,
    :active,
    :focus {
      outline: none;
      box-shadow: none;
      border-color: $silver;
    }
    .ant-select-selection:focus-visible {
      border-color: $novaBlue;
      box-shadow: 0 0 0 1px $novaBlue;
    }
    .ant-select-selection-selected-value {
      line-height: 40px;
    }
    &.small {
      height: 28px;
      .ant-select-selection-selected-value {
        line-height: 26px;
      }
    }
    &.medium {
      height: 40px;
    }
    &.large {
      height: 50px;
      .ant-select-selection-selected-value {
        line-height: 50px;
      }
    }
    &.disabled {
      .ant-select-selection {
        background-color: $lightCloud;

        &-selected-value {
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
    
    .ant-select-selection {
      &:hover,
      :active,
      :focus {
        outline: none;
        box-shadow: none;
        border-color: $silver;
      }
      &__rendered {
        height: 26px;
      }
      &-selected-value {
        height: inherit;
        display: flex;
        align-items: center;
      }
    }
  }
  &__label {
    @include font(sm-med);
    color: $novaBlue;
  }
  &__dropdown {
    .ant-select-dropdown-menu-item {
      @include font(sm);
      color: $darkAsh;
      background-color: $white !important;
      padding: 0 12px;
      &:hover {
        background-color: $novaBlue !important;
        color: $white;
        &[aria-disabled='true'] {
          background-color: $white !important;
        }
      }
    }
    &.small {
      .ant-select-dropdown-menu-item {
        padding: 2px 12px;
      }
    }
    &.medium {
      .ant-select-dropdown-menu-item {
        padding: 4px 12px;
      }
    }
    &.large {
      .ant-select-dropdown-menu-item {
        padding: 6px 12px;
      }
    }
    &.ant-select-dropdown--empty {
      .ant-select-dropdown-menu-item-disabled {
        background-color: $white !important;
      }
    }
  }
}
